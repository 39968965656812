import { css } from 'styled-components'

const fontDefaults = css`
  font-family: 'AreaNormal', sans-serif;
  font-style: normal;
`

const Typography = {
  eyebrow: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-weight: 900;
      font-size: 0.875rem;
      line-height: 1rem;
      letter-spacing: 0.01em;
      color: ${colors.blue400};
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 1rem;
      }
    `}
  `,
  h1: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-size: 2rem;
      line-height: 2.5rem;
      font-weight: 950;
      letter-spacing: -0.007em;
      color: ${colors.blue400};
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 4rem;
        line-height: 5rem;
      }
    `}
  `,
  h2: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 950;
      letter-spacing: -0.007em;
      color: ${colors.blue400};
      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 3.5rem;
        line-height: 4rem;
      }
    `}
  `,
  h3: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-weight: 950;
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${colors.blue400};

      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 3rem;
        line-height: 3.5rem;
      }
    `}
  `,
  h4: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-weight: 900;
      font-size: 1.5rem;
      line-height: 2.5rem;
      color: ${colors.blue400};

      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 2rem;
        line-height: 2rem;
      }
    `}
  `,
  h5: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-size: 1rem;
      letter-spacing: 0.02em;
      line-height: 1.5rem;
      color: ${colors.blue400};
      font-weight: 900;
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 1.125rem;
      }
    `}
  `,
  h6: css`
    ${({ theme: { colors } }) => css`
      ${fontDefaults};
      font-size: 1rem;
      letter-spacing: 0.02em;
      line-height: 1.5rem;
      color: ${colors.blue400};
      font-weight: 900;
    `}
  `,
  calculatorTableRow: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-size: 1rem;
      letter-spacing: 0.01em;
      line-height: 2.5rem;
      color: ${colors.blue400};
      @media screen and (max-width: ${breakpoints.tabletMin}) {
        font-size: 0.875rem;
        line-height: 2rem;
      }
      @media screen and (max-width: ${breakpoints.mobileMax}) {
        letter-spacing: 0.01em;
      }
    `}
  `,
  headerLabel: css`
    ${({ theme: { colors, breakpoints } }) => css`
      ${fontDefaults};
      font-weight: 600;
      letter-spacing: 0.02em;
      color: ${colors.black400};
      font-size: 0.75rem;
      line-height: 1.5rem;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 1rem;
      }
    `}
  `,
  buttonText: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 0.9375rem;
      color: ${colors.blue400};

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        font-size: 0.9375rem;
        //line-height: 1rem;
      }
    `}
  `,
  inputHeader: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-weight: 900;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.01em;
      color: ${colors.blue400};
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 0.8125rem;
      }
    `}
  `,
  inputText: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-size: 0.875rem;
      line-height: 2rem;
      font-weight: 600;
      letter-spacing: 0.01em;
      color: ${colors.black400};
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 1.125rem;
      }
    `}
  `,
  pSmall: css`
    ${({ theme: { colors } }) => css`
      ${fontDefaults};
      font-weight: normal;
      font-size: 0.9375rem;
      line-height: 1.75rem;
      letter-spacing: 0.02em;
      color: ${colors.blue400};
    `}
  `,
  pBody: css`
    ${({ theme: { breakpoints, colors } }) => css`
      ${fontDefaults};
      font-weight: normal;
      font-size: 1rem;
      line-height: 2rem;
      letter-spacing: 0.01em;
      color: ${colors.blue400};
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        font-size: 1.125rem;
      }
    `}
  `,
}

export default Typography
