import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import { ExtraStyle } from '../../types/Types'
import PrismicLink from './PrismicLink'

interface InlineLinkProps {
  link: PrismicLinkProps
  extraWrapperStyle?: ExtraStyle
  extraLinkStyle?: ExtraStyle
}

const SWrapper = styled.span<{
  extraLinkStyle?: ExtraStyle
  extraWrapperStyle?: ExtraStyle
  type?: string
}>`
  ${({ theme: { colors }, extraLinkStyle, extraWrapperStyle }) => css`
    display: inline-block;
    a {
      border-bottom: 0.1875rem solid ${colors.blue200};
      padding-bottom: 0.25rem;
      position: relative;
      color: ${colors.blue400};
      font-weight: bold;
      font-size: 1rem;
      line-height: 1rem;
      transition: all 320ms ease;
      &:hover {
        border-bottom: 0.1875rem solid ${colors.blue400};
      }
      ${extraLinkStyle}
    }
    ${extraWrapperStyle}
  `}
`
const PrismicInlineLink: FunctionComponent<InlineLinkProps> = ({ link, extraLinkStyle, extraWrapperStyle, children }) => (
  <SWrapper extraLinkStyle={extraLinkStyle} extraWrapperStyle={extraWrapperStyle} type={link.link_type}>
    <PrismicLink link={link}>{children}</PrismicLink>
  </SWrapper>
)

export default PrismicInlineLink
