import { convertNumberToCurrency } from '../utils/helpers'
import { calculatorConstants } from './Constants'

export const Translations = {
  is: {
    common: {
      back: 'Til baka',
      checkItOut: 'Kynntu þér málið',
      funding: 'Fjármögnun',
    },
    openGraphText: {
      title: 'Lykill',
      description:
        'Lykill býður góð kjör og skjóta þjónustu hvort sem þú ert á höttunum eftir bílaláni, langtímaleigu eða fyrirtækjaþjónustu.',
      image: 'https://images.prismic.io/lykill/8621af5e-7ef7-46f1-af90-b27027271b45_Lykilllogo_landscape.png',
    },
    calculator: {
      heading: 'Reiknaðu þína leið',
      price: 'Kaupverð',
      deposit: 'Innborgun',
      contractLength: 'Lengd samnings',
      breakdown: 'Sjá nánar',
      monthlyPayment: 'Mánaðargreiðsla',
      interestRate: 'Vextir',
      loanPrincipal: 'Höfuðstóll láns',
      applyForALoan: 'Að sækja um lán',
      greenLoan: 'Græn lán',
      loanPercentage: 'Fjármögnunarhlutfall',
    },
    breakdown: {
      breakdown: 'Sundurliðun',
      paidAmount: 'Útborguð upphæð',
      loanCost: 'Lántökugjald',
      registrationFee: 'Þinglýsingargjald',
      interestCost: 'Vaxtakostnaður',
      repaymentMethod: 'Greiðslufyrirkomulag',
      annualPercentageRate: 'Árleg hlutfallstala kostnaðar',
      totalPaid: 'Samtals greitt',
      equalPayments: 'Jafnar greiðslur',
      payment: 'Greiðsla',
      paymentDate: 'Gjalddagi',
      installment: 'Afborgun',
      interest: 'Vextir',
      cost: 'Kostnaður',
      remainder: 'Eftirstöðvar',
      paymentSchedule: 'Greiðsluáætlun',
      showAllPayments: 'Sýna allar greiðslur',
      totalRow: 'Samtals',
      yourLoan: 'Lánið þitt',
    },
    navigation: {
      backHome: 'Aftur heim',
      carLoans: 'Bílalán',
      longTermRental: 'Langtímaleiga',
      carSales: 'Bílasala',
      companies: 'Fyrirtæki',
      myPages: 'Mínar síður',
      searchPlaceholder: 'Leita...',
      search: 'Leita',
      seeAllResults: 'Sjá allar niðurstöður',
    },
    searchEngine: {
      pages: 'Síður',
      textResults: 'Textaniðurstöður',
      searchPlaceholder: 'Leita...',
      search: 'Leita',
      seeAllResults: 'Sjá allar niðurstöður',
      result: 'niðurstaða',
      results: 'niðurstöður',
      mobileSearchTitle: 'Leitaðu hér á Lykill.is',
    },
    contentSections: {
      newsArticles: {
        seeAll: 'Sjá allar greinar',
      },
    },
    serviceProviders: {
      type: 'Tegund',
      provider: 'Umboð',
      address: 'Þjónustuverkstæði',
      phone: 'Sími',
    },
    bilasolur: {
      error: 'Því miður fór eitthvað úrskeiðis við það að ná í gögn frá Bílasölur.is',
      tooOld:
        'Því miður er þetta ökutæki of gamalt fyrir lánveitingu. Endilega hafðu samband við okkur í síma 540-1710 eða á netfangið bilar@lykill.is',
      vehiclePriceTooLow: `Lágmarkskaupverð er ${convertNumberToCurrency(
        calculatorConstants.MIN_PRICE_AMOUNT
      )} Endilega hafðu samband við okkur í síma 540-1710 eða á netfangið bilar@lykill.is`,
      monthsPlusAgeTooMuch: 'Samanlagður aldur bifreiðar og lánstími er að hámarki 12 ár',
      depositUnderTwentyPercent:
        'Lágmarksinnborgun þarf að vera 20% þar sem aldur bíls og lánstími samanlagt er yfir níu ár.',
      contractLengthWithMinDepositOverLimit: 'Hámarks lengd láns er 5 ár (60 mánuðir) þar sem innborgun er undir 20%',
    },
    footer: {
      openingHours: 'Opnunartími',
      allWeek: 'alla virka daga',
      closedWeekends: 'Lokað um helgar',
      bankAccountNumber: 'Reikn. 0701-26-021260',
    },
    contact_us: {
      successful_submission_text: 'Skilaboðin þín voru móttekin. Við munum svara þér eins fljótt og mögulegt er.',
      failed_submission_text:
        'Ekki tókst að senda skilaboðin þín. Vinsamlegast reyndu aftur, eða hafðu samband við okkur símleiðis.',
    },
  },
}
