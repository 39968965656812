import {
  IDocumentSlice,
  IDocumentSliceLinks,
  IFeaturedPagesLinks,
  IFeaturedPagesSlice,
  TDocumentSlicePrimary,
} from '../prismicTypes'
import { getDocumentsByIds } from './prismicRouteHelpers'

export const convertDocumentSlice = async (document: IDocumentSliceLinks): Promise<IDocumentSlice[]> => {
  const sliceIds = document.items
    .filter((slice) => !slice.documentslice.isBroken)
    .map((slice) => {
      return slice.documentslice.id || ''
    })
  const sliceDocuments = await getDocumentsByIds(sliceIds)

  const slices = sliceDocuments.results.map((slice) => {
    return {
      slice_type: slice.type,
      primary: slice.data as TDocumentSlicePrimary,
    }
  })

  return slices
}

export const convertFeaturedPages = async (featuredPages: IFeaturedPagesLinks): Promise<IFeaturedPagesSlice[]> => {
  const sliceIds = featuredPages.items
    .filter((slice) => !slice.featured_page.isBroken)
    .map((slice) => {
      return slice.featured_page.id || ''
    })
  const sliceDocuments = await getDocumentsByIds(sliceIds)

  const pages = sliceDocuments.results.map((slice) => ({
    image: slice.data.image,
    description: slice.data.description,
    label: slice.data.label,
    title: slice.data.title,
    link: { type: 'page', link_type: 'Document', uid: slice.uid },
  }))

  return [
    {
      slice_type: 'featured_pages',
      items: pages,
      primary: {},
    },
  ]
}
