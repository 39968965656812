import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { SearchState } from 'react-instantsearch-core'
import { useRouteChange } from '../../RoutingProvider'
import { algoliaIndexName } from '../../../utils/algoliaHelpers'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import SearchBox from './SearchBox'
import Hits from './Hits'
import { useOnClickOutside } from '../../../utils/hooks'
import { searchClient } from '../../../utils/helpers'
import { routingPaths } from '../../../data/Constants'

interface IProps {
  onClose: () => void
  onOpen: () => void
}
const SearchHeader: React.FC<IProps> = ({ onClose, onOpen }) => {
  const [searchState, setSearchState] = useState<SearchState>()
  const [isSearchOpen, setSearchOpened] = useState<boolean>(false)
  const { handleRouteChange } = useRouteChange()
  const queryLength = searchState?.query?.length ?? 0
  const ref = useRef(null)

  const resetSearch = () => {
    setSearchState(undefined)
    setSearchOpened(false)
    onClose()
  }
  useOnClickOutside(ref, resetSearch)
  return (
    <SCWrapper isSearchOpen={isSearchOpen}>
      <InstantSearch
        onSearchStateChange={(state) => setSearchState(state)}
        indexName={algoliaIndexName}
        searchClient={searchClient}
        stalledSearchDelay={500}
      >
        <Configure hitsPerPage={6} />
        <div ref={ref} className="search">
          <SearchBox
            id="search"
            isExpanded={isSearchOpen}
            onClick={() => {
              onOpen()
              setSearchOpened(true)
            }}
            value={searchState?.query ?? ''}
            onEnterInInput={(event: any) => {
              resetSearch()
              handleRouteChange({
                event,
                //TODO english version
                url: `/${routingPaths.search}?query=${searchState?.query}`,
                isNextLink: true,
              })
            }}
          />
          {queryLength > 0 && isSearchOpen && (
            <Hits
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onHitClick={() => {
                resetSearch()
              }}
              onShowAllResultsClick={(event: any) => {
                resetSearch()
                handleRouteChange({
                  event,
                  url: `/${routingPaths.search}?query=${searchState?.query}`,
                  isNextLink: true,
                })
              }}
            />
          )}
        </div>
      </InstantSearch>
    </SCWrapper>
  )
}

export default SearchHeader

const SCWrapper = styled.button<{ isSearchOpen: boolean }>`
  ${({ theme: { colors, breakpoints }, isSearchOpen }) => css`
    background: none;
    text-align: left;
    border-radius: 0.5rem;
    height: 2.5rem;
    ${!isSearchOpen &&
    css`
      &:hover {
        background-color: ${colors.orange400};
      }
    `}
    ${isSearchOpen &&
    css`
      border: none;
      width: auto;
      position: static;
      top: 6.25rem;
      z-index: 2;
      margin-right: 0.3125rem;
    `}
    @media (min-width: ${breakpoints.desktopMid}) {
      width: auto;
      margin-right: 1rem;
      position: static;
    }
  `}
`
