import React, { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

const SButton = styled.button<{ menuIsOpen: boolean }>`
  ${({ theme: { colors }, menuIsOpen }) => css`
    outline: none;
    border: none;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
    font-family: 'Graphik', sans-serif;
    background: ${colors.white};
    transition: width 0.2s, background 0.2s;

    ${menuIsOpen &&
    css`
      z-index: 4;
      width: 3rem;
    `}

    > svg {
      min-width: 1.5rem;
      min-height: 1.5rem;
    }
  `}
`

const SHamburger = styled.span`
  ${({ theme: { breakpoints } }) => css`
    display: block;
    width: 1rem;
    height: 1rem;
    position: relative;

    @media (min-width: ${breakpoints.tabletMin}) {
      width: 1.5rem;
    }
  `}
`

const SLine = styled.span<{ menuIsOpen: boolean; type: string }>`
  ${({ theme: { colors }, menuIsOpen, type }) => css`
    position: absolute;
    left: 0;
    display: block;
    width: 1.125rem;
    height: 0.125rem;
    border-radius: 0.125rem;
    transition: 0.2s ease-in-out;
    transform: rotate(0deg) translateY(-50%);
    transform-origin: left center;
    opacity: 1;
    background: ${colors.blue400};

    ${type === 'first' &&
    css`
      top: 0.125rem;

      ${menuIsOpen &&
      css`
        top: -0.125rem;
        width: 1.5rem;
        transform: rotate(45deg);
      `}
    `}

    ${type === 'center' &&
    css`
      top: 50%;
      transform: translateY(-50%);
      ${menuIsOpen &&
      css`
        left: 0;
        width: 0;
        opacity: 0;
      `}
    `}

    ${type === 'last' &&
    css`
      bottom: 0;

      ${menuIsOpen &&
      css`
        bottom: -0.0625rem;
        width: 1.5rem;
        transform: rotate(-45deg);
      `}
    `}
  `}
`
export const Hamburger = ({
  toggle,
  menuIsOpen,
}: {
  toggle: (event: MouseEvent<HTMLButtonElement>) => void
  menuIsOpen: boolean
}) => (
  <SButton menuIsOpen={menuIsOpen} onClick={toggle}>
    <SHamburger>
      <SLine menuIsOpen={menuIsOpen} type="first" />
      <SLine menuIsOpen={menuIsOpen} type="center" />
      <SLine menuIsOpen={menuIsOpen} type="last" />
    </SHamburger>
  </SButton>
)
