import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { PrismicLinkProps } from '../../prismicBaseTypes'

import { ExtraStyle } from '../../types/Types'
import { linkResolver } from '../../utils/prismicHelpers'
import { useRouteChange } from '../RoutingProvider'

interface IProps {
  extraStyle?: ExtraStyle
  link: PrismicLinkProps
  ariaLabel?: string
}

const SAnchor = styled.a<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle }) => css`
    ${extraStyle}
  `}
`

const PrismicLink: FunctionComponent<IProps> = ({ extraStyle, link, children, ariaLabel }) => {
  const { handleRouteChange } = useRouteChange()
  let target
  let modifiedLink: string
  let isNextLink = false
  switch (link?.link_type) {
    case 'Web': {
      target = {
        target: link.target,
        rel: 'noopener',
      }
      modifiedLink = link.url || '/'
      break
    }
    case 'Document':
      {
        modifiedLink = linkResolver(link)
        isNextLink = true
      }
      break
    case 'Media':
      {
        target = {
          target: '_blank',
          rel: 'noopener',
        }
        modifiedLink = link.url || '/'
      }
      break

    default:
      {
        modifiedLink = '/'
      }
      break
  }

  return (
    <SAnchor
      {...target}
      href={modifiedLink}
      extraStyle={extraStyle}
      onClick={(event) => handleRouteChange({ event, url: modifiedLink, isNextLink })}
      aria-label={ariaLabel}
    >
      {children}
    </SAnchor>
  )
}

export default PrismicLink
