import Image from 'next/image'
import Link from 'next/link'

import styled, { css } from 'styled-components'
import { GridStyles } from '../../styles/gridStyles'
import MainGridContainer from '../grid/MainGridContainer'
import { Gutters } from '../../types/Types'
import { INavigation } from '../../prismicTypes'
import PrismicLink from '../Links/PrismicLink'
import { Hamburger } from './Hamburger'
import React, { useEffect, useState } from 'react'
import { useRouteChange } from '../RoutingProvider'
import PrismicIconLink from '../Links/PrismicIconLink'
import IconSearch from '../../public/svg/IconSearch'
import { routingPaths } from '../../data/Constants'

interface IProps {
  links: INavigation.ILinks[]
  iconLinks: INavigation.IIconLinks[]
}

const MobileMenu: React.FC<IProps> = ({ links, iconLinks }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const { routeChanging } = useRouteChange()

  useEffect(() => {
    if (routeChanging && isMobileMenuOpen) {
      setIsMobileMenuOpen(false)
    }
  }, [routeChanging])

  useEffect(() => {
    document.body.style.overflowY = isMobileMenuOpen ? 'hidden' : 'auto'
  }, [isMobileMenuOpen])

  return (
    <SCMobileMenu>
      <MainGridContainer gutters={Gutters.Both}>
        <>
          <SCTopBar>
            <Link href="/">
              <Image src="/svg/lykill_logo.svg" width={119} height={36} alt="Logo" />
            </Link>
            <SCTopRightButtons>
              <SCSearchButton href={'/' + routingPaths.search} isOpen={isMobileMenuOpen}>
                <IconSearch />
              </SCSearchButton>
              <Hamburger menuIsOpen={isMobileMenuOpen} toggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
            </SCTopRightButtons>
          </SCTopBar>
          <SCMenuContent isOpen={isMobileMenuOpen}>
            <SCLinks>
              {links.map((link, index) => (
                <PrismicLink link={link.link} key={`${link.link_text}${index}`}>
                  <SCLink>{link.link_text}</SCLink>
                </PrismicLink>
              ))}
            </SCLinks>
            <SCIconLinks>
              {iconLinks.map((link, index) => (
                <PrismicIconLink
                  icon={link.icon}
                  text={link.link_text}
                  link={link.link}
                  key={`${link.link_text}${index}`}
                />
              ))}
            </SCIconLinks>
          </SCMenuContent>
        </>
      </MainGridContainer>
    </SCMobileMenu>
  )
}

export default MobileMenu

const SCMobileMenu = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.white};
    width: 100vw;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
  `}
`

const SCLink = styled.span`
  ${({ theme: { colors } }) => css`
    margin-right: 1rem;
    color: ${colors.blue400};
    font-size: 2rem;
    font-weight: 950;
    & > a {
      margin-top: 2rem;
      font-size: 2rem;
      font-weight: 950;
      line-height: 2.5rem;
    }
  `}
`

const SCTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.875rem 0;
  grid-column: 1 / -1;
`
const SCSearchButton = styled.a<{ isOpen: boolean }>`
  ${({ theme: { colors }, isOpen }) => css`
    background-color: ${colors.grey200};
    border-radius: 6.25rem;
    height: 2.5rem;
    width: 2.5rem;
    display: ${isOpen ? 'flex' : 'none'};
    svg {
      margin: 0.5rem 0 0 0.625rem;
    }
  `}
`

const SCTopRightButtons = styled.div`
  display: flex;
  align-items: center;
`

const SCLinks = styled.span`
  display: flex;
  flex-direction: column;

  & > a {
    margin-top: 2rem;
    font-size: 2rem;
    font-weight: 950;
    line-height: 2.5rem;
  }
`

const SCIconLinks = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-area: il;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-botttom: 4.875rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: -0.75rem;
    }
  `}
`

const SCMenuContent = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    ${GridStyles.gridColumnsAll};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 1 / -1;
    height: calc(100vh - 6.75rem); /* allows for header height */
    @keyframes fadeIn {
      0% {
        opacity: 0;
        height: calc(100vh - 6.75rem);
      }
      100% {
        height: calc(100vh - 6.75rem);
        opacity: 1;
      }
    }

    ${isOpen && 'animation: fadeIn 0.7s ease forwards;'}
    display: ${isOpen ? 'flex' : 'none'};
  `}
`
