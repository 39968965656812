import { theme } from '../../styles/globalStyles'

const IconArrowRight: React.FC = () => {
  //old color #323232
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 9L5 5L1 1"
        stroke={theme.colors.black400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default IconArrowRight
