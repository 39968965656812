import React from 'react'
import styled, { css } from 'styled-components'
import { ExtraStyle } from '../../types/Types'

interface Props {
  extraStyle?: ExtraStyle
  children: React.ReactNode
}

const GridItem: React.FC<Props> = ({ extraStyle, children }) => {
  return (
    <SCGridItem extraStyle={extraStyle}>
      <>{children}</>
    </SCGridItem>
  )
}

export default GridItem

const SCGridItem = styled.div<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${extraStyle}
  `}
`
