import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import { ExtraStyle } from '../../types/Types'

const BulletListItem = styled.li<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle, theme: { colors } }) => css`
    ${Typography.pSmall};
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${colors.black400};
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;

    a {
      line-height: 2rem;
    }

    ${extraStyle}
  `}
`
export default BulletListItem
