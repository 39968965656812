import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '.'
import { calculatorConstants } from '../data/Constants'

interface LoanState {
  price: number
  deposit: number
  contractLength: number
  errorMessage: string
}

const initialState: LoanState = {
  price: 3500000,
  deposit: 1750000,
  contractLength: 84,
  errorMessage: '',
}

export const loanSlice = createSlice({
  name: 'loan',
  initialState,
  reducers: {
    updatePrice: (state, action: PayloadAction<number>) => {
      const newPrice = action.payload
      state.price = newPrice
      if (newPrice < state.deposit) {
        if (newPrice > calculatorConstants.MIN_PRICE_AMOUNT) {
          //do nothing when the use is inputing his price into the calculator...
        } else if (newPrice < calculatorConstants.MAX_PRICE_AMOUNT) {
          state.deposit = newPrice / 2
        } else {
          state.deposit = calculatorConstants.MAX_PRICE_AMOUNT / 2
        }
      }
    },
    updateDeposit: (state, action: PayloadAction<number>) => {
      const newDeposit = action.payload
      state.deposit = newDeposit
    },
    updateMonths: (state, action: PayloadAction<number>) => {
      state.contractLength = action.payload
    },
    updateErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  },
})

export const { updatePrice, updateDeposit, updateMonths, updateErrorMessage } = loanSlice.actions

export const selectPrice = (state: RootState): number => state.loan.price
export const selectDeposit = (state: RootState): number => state.loan.deposit
export const selectMonths = (state: RootState): number => state.loan.contractLength
export const selectErrorMessage = (state: RootState): string => state.loan.errorMessage

export default loanSlice.reducer
