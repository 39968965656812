import React from 'react'
import Script from 'next/script'
import { canUseDOM } from '../utils/canUseDom'

const SEGMENT_WRITE_KEY = process.env.SEGMENT_WRITE_KEY || ''

const SegmentScript = () => {
  // @ts-ignore
  if (!canUseDOM() || (window.analytics && window.analytics?.initialized)) {
    return null
  }

  if (!SEGMENT_WRITE_KEY) {
    return null
  }

  return (
    <Script
      type="text/plain" // intentionally not text/javascript
      data-consent="analytics"
      id="segment-script"
      dangerouslySetInnerHTML={{
        __html: `
                !function(){
                    var analytics=window.analytics=window.analytics||[];
                    if(!analytics.initialize)
                        if(analytics.invoked) {
                            return null;
                        }
                        else {
                            analytics.invoked=!0;
                            analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
                            analytics.factory=function(e){
                                return function(){
                                    if(window.analytics.initialized)
                                        return window.analytics[e].apply(window.analytics,arguments);
                                    var i=Array.prototype.slice.call(arguments);
                                    i.unshift(e);
                                    analytics.push(i);
                                    return analytics
                                }
                            };
                            for(var i=0;i<analytics.methods.length;i++){
                                var key=analytics.methods[i];
                                analytics[key]=analytics.factory(key)
                            }
                            analytics.load=function(key,i){
                                var t=document.createElement("script");
                                t.type="text/javascript";
                                t.async=!0;
                                t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
                                var n=document.getElementsByTagName("script")[0];
                                n.parentNode.insertBefore(t,n);
                                analytics._loadOptions=i;
                            };
                            analytics._writeKey="${SEGMENT_WRITE_KEY}";
                            analytics.SNIPPET_VERSION="4.16.1";
                            analytics.load("${SEGMENT_WRITE_KEY}");
                            analytics.page();
                        }
                }();
            `,
      }}
    />
  )
}

export default SegmentScript
