import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'
import { Translations } from '../../data/Translations'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import Typography from '../../styles/typography'

interface IProps {
  link: PrismicLinkProps
  text: string
}

const MyPagesButton: React.FC<IProps> = ({ link, text }) => {
  const { navigation } = Translations.is

  return (
    <SCMyPagesLink href={link?.url || 'https://minn.lykill.is'}>
      <Image src="/svg/profile.svg" width={24} height={24} alt="Profile" />
      <SCButtonText>{text || navigation.myPages}</SCButtonText>
    </SCMyPagesLink>
  )
}

export default MyPagesButton

const SCMyPagesLink = styled.a`
  ${({ theme: { colors, breakpoints } }) => css`
    flex: none;
    display: flex;
    align-items: center;
    border: 0.09375rem solid ${colors.orange400};
    border-radius: 0.5rem;
    padding: 0.75rem;
    margin-top: 5rem;
    height: 2.5rem;
    font-size: 0.8125rem;
    position: fixed;
    bottom: 2rem;
    width: calc(100% - 2rem);
    transition: background-color 320ms ease;
    background-color: ${colors.white};
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      width: auto;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: 0;
      position: relative;
      bottom: 0;
      &:hover {
        background-color: ${colors.orange400};
      }
    }
  `}
`

const SCButtonText = styled.span`
  ${Typography.buttonText};
  font-size: 0.8125rem;
`
