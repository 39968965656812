import { theme } from '../../styles/globalStyles'

const IconQuestionBubbles: React.FC = () => {
  return (
    <svg width="32" height="32" fill="none">
      <path
        d="M9.444 29.084a6.53 6.53 0 01-2.754-.605l-4.024.854.842-4.032a6.534 6.534 0 01-.61-2.762 6.539 6.539 0 116.54 6.538"
        stroke={theme.colors.blue400}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.816 16.025A8.833 8.833 0 018 12.32c0-5.356 4.797-9.654 10.667-9.654 5.869 0 10.666 4.298 10.666 9.654 0 3.165-1.682 5.952-4.265 7.71.001 1.007-.001 2.366-.001 3.793L20.88 21.76c-.716.137-1.456.212-2.214.212-.948 0-1.864-.122-2.742-.333M6.393 22.62a.067.067 0 11-.094.094.067.067 0 01.094-.095M9.393 22.62a.067.067 0 11-.094.094.067.067 0 01.094-.095M12.393 22.62a.067.067 0 11-.094.094.067.067 0 01.095-.095"
        stroke={theme.colors.blue400}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.666 13.39c0-.876.542-1.35 1.084-1.716.531-.357 1.062-.822 1.062-1.68a2.145 2.145 0 10-4.291 0M18.667 16.611a.134.134 0 00-.134.134.134.134 0 00.267 0 .134.134 0 00-.133-.134"
        stroke={theme.colors.blue400}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconQuestionBubbles
