import Fade from '@mui/material/Fade/Fade'
import React, { SyntheticEvent } from 'react'
import { connectHits } from 'react-instantsearch-dom'
import { Hit, HitsProvided } from 'react-instantsearch-core'
import styled, { css } from 'styled-components'
import { IHitProps } from '../../../types/Search'
import SearchHits from './SearchHits'
import { Translations } from '../../../data/Translations'
import IconArrowRight from '../../../public/svg/IconArrowRight'
interface IProps extends HitsProvided<IHitProps> {
  onHitClick: (event: React.MouseEvent<HTMLElement>) => void
  onShowAllResultsClick: (event: SyntheticEvent | React.MouseEvent<HTMLButtonElement>) => void
}
const Hits = ({ hits, onHitClick, onShowAllResultsClick }: IProps) => {
  const pageHits: Hit<IHitProps>[] = []
  const textHits: Hit<IHitProps>[] = []
  const { searchEngine } = Translations.is

  hits.forEach((hit) => (hit.isFeatured ? pageHits.push(hit) : textHits.push(hit)))

  return hits.length > 0 ? (
    <Fade in={hits.length > 0}>
      <SCModalContent>
        {pageHits.length > 0 && <SearchHits hits={pageHits} onHitClick={onHitClick} title={searchEngine.pages} />}
        {textHits.length > 0 && <SearchHits hits={textHits} onHitClick={onHitClick} title={searchEngine.textResults} />}
        <SButtonWrapper>
          <SCSeeAllResults onClick={onShowAllResultsClick}>
            {searchEngine.seeAllResults}
            <SCArrowWrapper>
              <IconArrowRight />
            </SCArrowWrapper>
          </SCSeeAllResults>
        </SButtonWrapper>
      </SCModalContent>
    </Fade>
  ) : (
    <></>
  )
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connectHits(Hits)

const SCArrowWrapper = styled.span`
  ${({ theme: { colors, breakpoints } }) => css`
    display: none;
    align-self: center;
    padding: 1rem 0 1rem 0.5rem;
    color: ${colors.blue400};
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: inline-block;
    }
  `}
`
const SCSeeAllResults = styled.button`
  ${({ theme: { colors } }) => css`
    display: flex;
    padding: 1rem;
    border: 0.09375rem solid ${colors.orange400};
    color: ${colors.blue400};
    &:hover {
      background-color: ${colors.orange400};
    }
  `}
`

const SCModalContent = styled.div`
  ${({ theme: { colors, shadow, breakpoints } }) => css`
    position: absolute;
    margin-top: 0.3rem;
    box-shadow: ${shadow};
    border-radius: 1.5rem;
    padding: 1rem;
    background-color: ${colors.white};
    @media screen and (max-width: ${breakpoints.desktopMin}) {
    }
  `}
`

const SButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  button {
    height: 3.5rem;
    border-radius: 0.75rem;
  }
`
