import React, { FunctionComponent, ReactNode } from 'react'
import { Elements, RichText, RichTextBlock } from 'prismic-reactjs'
import { ExtraStyle } from '../../types/Types'
import { linkResolver } from '../../utils/prismicHelpers'
import PrismicInlineLink from '../Links/PrismicInlineLink'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import Paragraph from '../Paragraph'
import BulletListItem from '../common/BulletListItem'

// -- Function to add unique key to props
const propsWithUniqueKey = function (props: { className?: string }, key: number) {
  return Object.assign(props || {}, { key })
}

// -- HTML Serializer

interface PrismicRichTextProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  richText: RichTextBlock[]
  extraStyle?: ExtraStyle
  extraLinkStyle?: ExtraStyle
  extraParagraphStyle?: ExtraStyle
}
const PrismicRichText: FunctionComponent<PrismicRichTextProps> = ({
  richText,
  extraStyle,
  extraLinkStyle,
  extraParagraphStyle,
}) => {
  const htmlSerializer = function (
    type: string,
    element: {
      linkTo: {
        url?: string
        target?: string
        type?: string | undefined
        uid?: string | undefined
        lang?: string | undefined
        tags?: string[] | undefined
      }
      url: string
      alt: string
      label: string
      oembed: {
        embed_url: string
        type: string
        provider_name: string
        html: string
      }
      data: PrismicLinkProps
    },
    content: string,
    children: ReactNode[],
    key: string
  ) {
    let props = {}
    switch (type) {
      case Elements.heading1: // Heading 1
        return React.createElement('h1', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.heading2: // Heading 2
        return React.createElement('h2', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.heading3: // Heading 3
        return React.createElement('h3', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.heading4: // Heading 4
        return React.createElement('h4', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.heading5: // Heading 5
        return React.createElement('h5', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.heading6: // Heading 6
        return React.createElement('h6', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.paragraph: {
        // Paragraph
        // Check if there is an empty string in children, if so it should be treated as a break in text
        return children && children[0] !== null ? (
          <Paragraph key={key} extraStyle={extraParagraphStyle}>
            {children}
          </Paragraph>
        ) : (
          <br key={key} />
        )
      }

      case Elements.preformatted: // Preformatted
        return React.createElement('pre', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.strong: // Strong
        return React.createElement('strong', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.em: // Emphasis
        return React.createElement('em', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.listItem: // Unordered List Item
        return (
          <BulletListItem key={key} extraStyle={extraStyle}>
            {children}
          </BulletListItem>
        )

      case Elements.oListItem: // Ordered List Item
        return React.createElement('li', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.list: // Unordered List
        return React.createElement('ul', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.oList: // Ordered List
        return React.createElement('ol', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.image: {
        // Image
        const linkUrl = element.linkTo ? element.linkTo.url || linkResolver(element.linkTo) : null
        const linkTarget = element.linkTo && element.linkTo.target ? { target: element.linkTo.target } : {}
        const linkRel = linkTarget.target ? { rel: 'noopener' } : {}
        const img = React.createElement('img', {
          src: element.url,
          alt: element.alt || '',
          height: '100%',
          width: '100%',
        })
        return React.createElement(
          'p',
          propsWithUniqueKey({ className: [element.label || '', 'block-img'].join(' ') }, parseInt(key)),
          linkUrl
            ? React.createElement(
                'a',
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                Object.assign({ href: linkUrl }, linkTarget, linkRel) as any,
                img
              )
            : img
        )
      }

      case Elements.embed: {
        // Embed
        props = Object.assign(
          {
            'data-oembed': element.oembed.embed_url,
            'data-oembed-type': element.oembed.type,
            'data-oembed-provider': element.oembed.provider_name,
          },
          element.label ? { className: element.label } : {}
        )
        const embedHtml = React.createElement('div', {
          dangerouslySetInnerHTML: { __html: element.oembed.html },
        })
        return React.createElement('div', propsWithUniqueKey(props, parseInt(key)), embedHtml)
      }
      case Elements.hyperlink: // Link
        return (
          <PrismicInlineLink link={element.data} key={key} extraLinkStyle={extraLinkStyle}>
            {children}
          </PrismicInlineLink>
        )

      case Elements.label: // Label
        props = element.data ? Object.assign({}, { className: element.data.label }) : {}
        return React.createElement('span', propsWithUniqueKey(props, parseInt(key)), children)

      case Elements.span: // Span
        if (content) {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          return content.split('\n').reduce((acc: any[], p: any) => {
            if (acc.length === 0) {
              return [p]
            } else {
              const brIndex = (acc.length + 1) / 2 - 1
              const br = React.createElement('br', propsWithUniqueKey({}, brIndex))
              return acc.concat([br, p])
            }
          }, [])
        } else {
          return null
        }

      default:
        // Always include a default that returns null
        return null
    }
  }

  return <RichText render={richText} htmlSerializer={htmlSerializer} />
}

export default PrismicRichText
