import { AppProps } from 'next/app'

import GlobalStyle, { theme } from '../styles/globalStyles'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { ThemeProvider } from 'styled-components'
import store from '../store'
import Layout from '../components/Layout'
import '../public/fonts.css'
import RoutingProvider from '../components/RoutingProvider'
import CookieHubLoader from '../scripts/CookieHub'
import SegmentScript from '../scripts/Segment'

function MyApp({ Component, pageProps }: AppProps) {
  persistStore(store)

  return (
    <ThemeProvider theme={theme}>
      <CookieHubLoader />
      <SegmentScript />
      <GlobalStyle />
      <Provider store={store}>
        <RoutingProvider>
          <Layout
            notificationBanner={pageProps.notificationbanner}
            navigation={pageProps.navigation}
            footer={pageProps.footer}
          >
            <Component {...pageProps} />
          </Layout>
        </RoutingProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default MyApp
