import React from 'react'
import styled, { css } from 'styled-components'
import { ExtraStyle, Gutters } from '../../types/Types'

import GridContainer from './GridContainer'

type Props = {
  backgroundColor?: string
  gutters?: Gutters
  extraStyle?: ExtraStyle
  children: JSX.Element | JSX.Element[] | React.ReactNode
}

const MainGridContainer: React.FC<Props> = ({ backgroundColor, gutters = Gutters.Both, extraStyle, children }) => {
  const getGutterClassName = (gutters: Gutters) => {
    switch (gutters) {
      case Gutters.Both: {
        return SCMainGrid
      }
      case Gutters.LeftOnly: {
        return SCMainGridWithLeftGutter
      }
      case Gutters.RightOnly: {
        return SCMainGridWithRightGutter
      }
      case Gutters.None: {
        return SCMainGridWithNoGuttters
      }
    }
  }

  return (
    <SCWrapper
      extraStyle={css`
        ${getGutterClassName(gutters)};
        ${extraStyle};
      `}
      backgroundColor={backgroundColor}
    >
      <GridContainer>
        <>{children}</>
      </GridContainer>
    </SCWrapper>
  )
}

export default MainGridContainer

const SCWrapper = styled.section<{ backgroundColor?: string; extraStyle?: ExtraStyle }>`
  ${({ backgroundColor, extraStyle }) => css`
    background-color: ${backgroundColor ?? 'initial'};
    ${extraStyle};
  `}
`

const SCMainGridDisplay = css`
  ${({ theme: { breakpoints, gutters } }) => css`
    display: grid;
    grid-template-areas: '. content .';
    grid-column-gap: 0;

    grid-template-columns: minmax(${gutters.gutterSmallMobile}, auto) minmax(0, 1fr) minmax(
        ${gutters.gutterSmallMobile},
        auto
      );

    @media screen and (min-width: ${breakpoints.mobileMax}) {
      grid-template-columns: minmax(${gutters.gutterMobile}, auto) minmax(0, 1fr) minmax(${gutters.gutterMobile}, auto);
    }
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: minmax(${gutters.gutterTablet}, auto) minmax(0, 1fr) minmax(${gutters.gutterTablet}, auto);
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: minmax(${gutters.gutterDesktop}, auto) minmax(0, 1fr) minmax(
          ${gutters.gutterDesktop},
          auto
        );
    }
  `}
`
const SCMainGrid = css`
  ${({ theme: { breakpoints, gutters } }) => css`
    ${SCMainGridDisplay};
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: minmax(${gutters.gutterDesktop}, auto) minmax(auto, ${breakpoints.desktopMax}) minmax(
          ${gutters.gutterDesktop},
          auto
        );
    }
  `}
`

const SCMainGridWithLeftGutter = css`
  ${({ theme: { breakpoints, gutters } }) =>
    css`
      ${SCMainGridDisplay};
      grid-template-columns: minmax(${gutters.gutterTablet}, auto) minmax(0, 1fr) minmax(${gutters.gutterOff}, auto);
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-template-columns: minmax(${gutters.gutterDesktop}, 1fr) minmax(auto, ${breakpoints.desktopMax}) minmax(
            ${gutters.gutterOff},
            1fr
          );
      }
    `}
`

const SCMainGridWithRightGutter = css`
  ${({ theme: { breakpoints, gutters } }) =>
    css`
      ${SCMainGridDisplay};
      grid-template-columns: minmax(${gutters.gutterOff}, 1fr) minmax(0, 1fr) minmax(${gutters.gutterTablet}, 1fr);
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-template-columns: minmax(${gutters.gutterOff}, 1fr) minmax(auto, ${breakpoints.desktopMax}) minmax(
            ${gutters.gutterDesktop},
            1fr
          );
      }
    `}
`

const SCMainGridWithNoGuttters = css`
  ${({ theme: { breakpoints, gutters } }) =>
    css`
      ${SCMainGridDisplay};
      grid-template-columns: minmax(${gutters.gutterOff}, auto) minmax(0, 1fr) minmax(${gutters.gutterOff}, auto);
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-template-columns: minmax(${gutters.gutterOff}, auto) minmax(auto, ${breakpoints.desktopMax}) minmax(
            ${gutters.gutterOff},
            auto
          );
      }
    `}
`
