import { css } from 'styled-components'

export const GridStyles = {
  gridColumnsRight: css`
    ${({ theme: { breakpoints } }) => css`
      grid-column: 1 / 3;
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        grid-column: 4 / 7;
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-column: 7 / 13;
      }
    `}
  `,
  gridColumnsLeft: css`
    ${({ theme: { breakpoints } }) => css`
      grid-column: 1 / 3;
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        grid-column: 1 / 4;
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-column: 1 / 7;
      }
    `}
  `,
  gridColumnsAuto: css`
    grid-column: auto;
  `,
  gridColumnsAll: css`
    ${({ theme: { breakpoints } }) => css`
      grid-column: 1 / 3;
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        grid-column: 1 / 7;
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-column: 1 / 13;
      }
    `}
  `,
  gridColumnsTwoThirds: css`
    ${({ theme: { breakpoints } }) => css`
      grid-column: 1 / 3;
      @media screen and (min-width: ${breakpoints.tabletMin}) {
        grid-column: 1 / 5;
      }
      @media screen and (min-width: ${breakpoints.desktopMin}) {
        grid-column: 1 / 9;
      }
    `}
  `,
}
