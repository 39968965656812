import { createGlobalStyle } from 'styled-components'

export const theme = {
  colors: {
    orange400: '#f8bf23',
    orange200: '#fff2d7',
    orange100: '#ffffe9',
    blue400: '#456372',
    blue200: '#c1d8e4',
    blue100: '#e9f6ff',
    green400: '#73b933',
    green200: '#d9fabe',
    green100: '#f1ffe9',
    red400: '#ff6a57',
    red200: '#ffd9d9',
    red100: '#fff3f3',
    black400: '#363636',
    black200: '#5e5e5e',
    black100: '#d7d8db',
    grey400: '#dbdbdb',
    grey300: '#ebebeb',
    grey200: '#f0f0f0',
    grey100: '#fafafc',
    white: '#ffffff',
    transparent: '#ffffff00',
    borderGrey: '#E2E2E4',
    buttonHover: '#FFD257',
    bilasolurBlue: '#1F56A4',
  },

  shadow: '0 0.25rem 3.5rem rgba(0, 0, 0, 0.07)',

  containerWidths: {
    medium: '816px',
  },

  breakpoints: {
    smallMobileMax: '350px',
    mobileMid: '480px',
    mobileMax: '599px',
    tabletMin: '600px',
    tabletMid: '750px',
    tabletMax: '979px',
    desktopMin: '980px',
    desktopMinPlus: '1024',
    desktopMid: '1150px',
    desktopMidPlus: '1270px',
    desktopMax: '1440px',
    //new
    mobile: '0px',
    tablet: '680px',
    desktopXSmall: '960px',
    desktopSmall: '1280px',
    desktopMd: '1440px',
    desktopLarge: '1600px',
  },
  gutters: {
    gutterOff: '0',
    gutterDesktop: '9.75rem',
    gutterTabletDesktop: '2.5rem',
    gutterTablet: '3rem',
    gutterSmallMobile: '1rem',
    gutterMobile: '1.5rem',
  },
}

const GlobalStyle = createGlobalStyle`
  /* Default styles */
  html {
    padding: 0;
    margin: 0;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'AreaNormal', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    color: ${theme.colors.grey400}
  }

  * {
    box-sizing: border-box;
  }

  a {
    font-family: 'AreaNormal', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    color: inherit;
    text-decoration: none;
  }

  input,
  button {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
    margin-right: 0.75rem;
  }

  input[type='submit'].btn-block,
  input[type='reset'].btn-block,
  input[type='button'].btn-block {
    width: 100%;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.0625rem;
    text-overflow: '';
  }
  select::-ms-expand {
    display: none;
  }

  li {
    list-style-image: url('/svg/list_item.svg');
  }

  .MuiBackdrop-root {
    background-color: rgba(69, 99, 114, 0.8) !important;
    backdrop-filter: blur(64px);
  }  

  .ch2-allow-all-btn, .ch2-btn, .ch2-btn-primary, .ch2-save-settings-btn {
    border-radius: 0.75rem;
    background: ${theme.colors.orange400} !important;
    color: ${theme.colors.blue400} !important;
    font-size: 0.75rem !important;

    box-shadow: none !important;

    &:hover {
      background: ${theme.colors.buttonHover} !important;
      
    }
  }
  .ch2-open-settings-btn, .ch2-deny-all-btn {
    background: ${theme.colors.white} !important;
    border: 0.0625rem solid ${theme.colors.orange400} !important;
  }

  .ch2-settings, .ch2-settings-actions, .ch2-visible {
    @media screen and (min-width: ${theme.breakpoints.tabletMin}) {
      border-radius: 0.75rem !important;
    }
  }

  .ch2-dialog, .ch2-dialog-bottom, .ch2-visible {
    @media screen and (min-width: ${theme.breakpoints.tabletMin}) {
      margin: 1rem !important;
    }
    
  }
  .ch2-settings-tabs {
    > ul {
      display: inline-flex !important;
    }
  }

  .ch2-settings-options {
    @media screen and (max-width: ${theme.breakpoints.tabletMin}) {
      padding-bottom: 2rem !important;
    }
  }


`

export default GlobalStyle
