// -- Link resolution rules
import Prismic from '@prismicio/client'
import { Document } from '@prismicio/client/types/documents'
import { routingPaths } from '../data/Constants'
import { IPrismicSlice } from '../prismicBaseTypes'
import {
  IDocumentSliceLinks,
  IFeaturedPagesLinks,
  IFrontPage,
  INewsArticle,
  IPage,
  ISubPage,
  IThreeColumnNewsCardSlice,
  PrismicDocumentTypes,
} from '../prismicTypes'
import { convertDocumentSlice, convertFeaturedPages } from './prismicLinkConverters'
import { PRISMIC_PAGE_SIZE } from './prismicRouteHelpers'

const slicesWithDocumentData = ['document_slice', 'featured_pages']

// Manages the url links to internal Prismic documents
export const linkResolver = (doc: { type?: string; uid?: string; lang?: string; tags?: string[] }, anchor?: string) => {
  const firstDocTag = doc.tags?.[0]

  switch (doc?.type) {
    case 'page':
      return `/${doc.uid}${anchor ? '#' + anchor : ''}`
    case 'subpage':
      return `/${firstDocTag || 'page'}/${doc.uid}${anchor ? '#' + anchor : ''}`
    default:
      return `/`
  }
}

export const convertDocumentToSlices = async (document: IPrismicSlice): Promise<IPrismicSlice[]> => {
  switch (document.slice_type) {
    case 'document_slice':
      return await convertDocumentSlice(document as IDocumentSliceLinks)
    case 'featured_pages':
      return await convertFeaturedPages(document as IFeaturedPagesLinks)
    default:
      return []
  }
}

export const getLatestNewsPreview = async (top: number) => {
  const client = Client()

  const articlePreviews = await client.query(Prismic.Predicates.at('document.type', PrismicDocumentTypes.NewsArticle), {
    fetch: [
      `${PrismicDocumentTypes.NewsArticle}.title`,
      `${PrismicDocumentTypes.NewsArticle}.short_description`,
      `${PrismicDocumentTypes.NewsArticle}.published_date`,
    ],
    orderings: '[my.newsarticle.published_date desc]',
    pageSize: top,
  })

  return articlePreviews.results.map((doc) => ({
    title: doc.data.title[0].text,
    short_description: doc.data.short_description[0].text,
    published_date: doc.data.published_date,
    href: `/${routingPaths.news}/${doc.uid}`,
  }))
}

export const getContentByTypeAndTag = async (type: string, tag: string) => {
  const client = Client()

  const data = await client.query(
    [Prismic.Predicates.at('document.type', type), Prismic.Predicates.at('document.tags', [tag])],
    { pageSize: PRISMIC_PAGE_SIZE }
  )

  return data?.results
}

export const getSlicesFromDocument = async (document: Document): Promise<IPrismicSlice[]> => {
  if (!document.data.body) return [] as IPrismicSlice[]
  const slices = (await Promise.all(
    document.data.body.map((slice: IPrismicSlice) =>
      slicesWithDocumentData.includes(slice.slice_type) ? convertDocumentToSlices(slice) : slice
    )
  ).catch(() => [])) as IPrismicSlice[]
  return slices.flat()
}
export const getAllTemplates = async () => {
  const client = Client()
  const [frontpage, page, subpage, newsArticle] = await Promise.all([
    client.query(Prismic.Predicates.at('document.type', 'frontpage'), {
      pageSize: PRISMIC_PAGE_SIZE,
    }),
    client.query(Prismic.Predicates.at('document.type', 'page'), {
      pageSize: PRISMIC_PAGE_SIZE,
    }),
    client.query(Prismic.Predicates.at('document.type', 'subpage'), {
      pageSize: PRISMIC_PAGE_SIZE,
    }),
    client.query(Prismic.Predicates.at('document.type', 'newsarticle'), {
      pageSize: PRISMIC_PAGE_SIZE,
    }),
    // TODO Make sure to fetch also English tags when /en goes live
  ])
  return {
    frontPageData: frontpage.results as IFrontPage.IProps[],
    pageData: page.results as IPage.IProps[],
    subPageData: subpage.results as ISubPage.IProps[],
    newsArticleData: newsArticle.results as INewsArticle.IProps[],
  }
}

export const getFrontPageSlicesFromDocument = async (document: Document): Promise<IPrismicSlice[]> => {
  let slices = await getSlicesFromDocument(document)

  return await Promise.all(
    slices.map(async (slice) => {
      if (slice.slice_type === 'latest_news_articles') {
        const previews = await getLatestNewsPreview((slice as IThreeColumnNewsCardSlice).primary.number_of_articles)
        return { ...slice, newsArticlesPreview: previews }
      } else return slice
    })
  )
}

// Client method to query documents from the Prismic repo
const Client = (req?: unknown) =>
  Prismic.client(process.env.PRISMIC || '', {
    req: req || undefined,
    accessToken: process.env.API_TOKEN || undefined,
  })

export default Client
