import { PrismicLinkProps } from '../prismicBaseTypes'
import { useEffect, useState } from 'react'
import { ParsedUrlQuery } from 'querystring'
import algoliasearch from 'algoliasearch'
import { IsDateStrings as ISDateStrings } from '../data/Constants'
export const replaceDoubleHyphen = (text: string, replaceWith = String.fromCharCode(173)): string | null => {
  if (!text || text === '') {
    return null
  }
  return text.replace(/--/g, replaceWith)
}

export const convertNumberToCurrency = (value: number, suffix: string = 'kr.') => {
  const numberWithOneDecimal = value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&.')

  return `${numberWithOneDecimal.substring(0, numberWithOneDecimal.length - 2)} ${suffix}`
}

export const isPrismicLinkEmpty = (link: PrismicLinkProps): boolean => {
  return !link || link.link_type === 'Any'
}

export const useDebounce = (value: number, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState<number>(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}
export const containsBilasolurParameters = (query: ParsedUrlQuery): boolean => {
  return Boolean(query.cid) && Boolean(query.bid) && Boolean(query.sid) && query.page === 'reiknivel'
}
const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY as string
)

export const searchClient = {
  search(requests: any) {
    if (requests.every(({ params }: { params: { query: string } }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }
    const res = algoliaClient.search(requests)
    return res
  },
}

export const getISDateFormat = (sDate: Date): string => {
  // const strSplitDate = String(sDate).split(' ')
  // const date = new Date(strSplitDate[0])
  const date = new Date(sDate)
  return date
    ? date.getDate() + '. ' + ISDateStrings.monthsShort[date.getMonth()].toLowerCase() + ', ' + date.getFullYear()
    : ''
}

export const getMonthsSinceDate = (year: number, month: number): number => {
  const currentDate = new Date()
  //Get the year difference
  const differenceInMonths = currentDate.getMonth() + 1 + (currentDate.getFullYear() - year) * 12 - month
  return differenceInMonths
}

export function generateRandomMathExpression() {
  const num1 = Math.floor(Math.random() * 10) // Random number between 0 and 9
  const num2 = Math.floor(Math.random() * 10) // Random number between 0 and 9
  const operator = 'plús'

  const expression = `Hvað er ${num1} ${operator} ${num2}`
  return expression
}

export function calculateCorrectAnswer(randomMathExpression: string) {
  const match = /Hvað er (\w+) (plús|mínus) (\w+)/.exec(randomMathExpression)

  if (match) {
    const [, num1, operator, num2] = match

    // Calculate the correct answer based on the operator
    let correctAnswer
    switch (operator) {
      case 'plús':
        correctAnswer = parseInt(num1, 10) + parseInt(num2, 10)
        break
      default:
        correctAnswer = 0
    }

    return correctAnswer.toString() // Convert the correct answer to a string
  }

  // Handle the case when the regular expression doesn't match
  return ''
}

export function convertRandomMathExpressionToString(expression: string | undefined) {
  if (!expression) {
    return 'Expression is undefined'
  }

  const digitWords: Record<string, string> = {
    '0': 'núll',
    '1': 'einn',
    '2': 'tveir',
    '3': 'þrír',
    '4': 'fjórir',
    '5': 'fimm',
    '6': 'sex',
    '7': 'sjö',
    '8': 'átta',
    '9': 'níu',
  }

  const convertedExpression = expression.replace(/\d/g, (digit) => digitWords[digit] || digit)

  return convertedExpression
}
