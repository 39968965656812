import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { HitsProvided } from 'react-instantsearch-core'
import Typography from '../../../styles/typography'
import { useRouteChange } from '../../RoutingProvider'
import { IHitProps } from '../../../types/Search'
import Image, { ImageProps } from 'next/image'
interface IProps extends HitsProvided<IHitProps> {
  onHitClick: (event: React.MouseEvent<HTMLElement>) => void
  title: string
}

const searchHits = ({ hits, onHitClick, title }: IProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { handleRouteChange } = useRouteChange()
  return (
    <SContainer>
      <SCSmallTitle>{title}</SCSmallTitle>
      <SItemContainer>
        {hits.map((hit) => (
          <SItem key={hit.objectID}>
            <SAnchor
              href={hit.slug}
              onClick={(event) => {
                onHitClick(event)
                handleRouteChange({
                  event,
                  url: hit.slug,
                  isNextLink: true,
                })
              }}
            >
              {hit.image != '' && (
                <SImageContainer>
                  <SCardImage
                    src={hit.image}
                    layout="fixed"
                    width={80}
                    height={80}
                    alt=""
                    $isLoaded={imageLoaded}
                    onLoad={() => setImageLoaded(true)}
                  />
                </SImageContainer>
              )}

              <SCPageItem>
                <SCPageItemHeader>
                  {hit.title != 'null' ? <SCTitle>{hit.title}</SCTitle> : <SCTitle />}
                  <SCSlug>{hit.slug.substr(0, hit.slug.indexOf('#'))}</SCSlug>
                </SCPageItemHeader>
                <SCSmallParagraph>{hit.description}</SCSmallParagraph>
              </SCPageItem>
            </SAnchor>
          </SItem>
        ))}
      </SItemContainer>
    </SContainer>
  )
}

export default searchHits

const SCPageItemHeader = styled.div`
  display: flex;
  width: 100%;
`
const SCPageItem = styled.div`
  width: 100%;
`
const SCSlug = styled.p`
  ${Typography.pSmall}
  flex: auto;
  text-align: right;
  margin: 0;
`

const SCTitle = styled.h6`
  ${Typography.h6}
  text-align: left;
  margin: 0;
`

const SContainer = styled.div`
  margin-bottom: 2rem;
`
const SCSmallTitle = styled.h5`
  ${Typography.h5}
  font-size: 1.125rem;
  font-weight: 900;
`

const SItemContainer = styled.ul`
  padding-left: 0;
`

const SItem = styled.li`
  margin-top: 1.75rem;
  list-style: none;
`
const SCardImage = styled(Image)<ImageProps & { $isLoaded?: boolean }>`
  ${({ theme: { colors }, $isLoaded }) => css`
    background-color: ${colors.white};
    border-radius: 1.75rem;
    opacity: ${$isLoaded ? 1 : 0};
    transition: opacity 1s;
  `}
`
const SImageContainer = styled.div`
  display: block;
  margin-right: 1.75rem;
  transition: transform 320ms ease, opacity 320ms ease;
`
const SAnchor = styled.a`
  ${({ theme: { colors } }) => css`
    display: flex;
    padding: 0.75rem;
    background-color: ${colors.white};
    transition: background-color 320ms ease;
    border-radius: 1rem;
    &:focus,
    &:hover,
    &.focus-visible {
      background-color: ${colors.orange400};
    }
    &.focus-visible {
      border-radius: 0.75rem;
    }
  `}
`

const SCSmallParagraph = styled.span`
  ${Typography.pSmall}
  display: -webkit-box;
  margin-top: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
