import { theme } from '../styles/globalStyles'
import { convertNumberToCurrency } from '../utils/helpers'

export const mediaQueries = {
  isTabletOrSmaller: `(max-width: ${theme.breakpoints.desktopMin})`,
  isMobile: `(max-width: ${theme.breakpoints.tabletMin})`,
}
//Rules about deposits https://www.lykill.is/reiknivel/
//MIN_LOAN_AMOUNT  and MIN_DEPOSIT_PERCENTAGE
//has to be 10% of the price or at least the 150.000
export const calculatorConstants = {
  MAX_PRICE_AMOUNT: 30000000,
  MIN_PRICE_AMOUNT: 900000,
  MIN_DEPOSIT_AMOUNT: 150000,
  MIN_LOAN_AMOUNT: 750000,
  SLIDER_STEP_AMOUNT: 50000,
  SLIDER_STEP_DEPOSIT: 10000,
  SLIDER_STEP_MONTHS: 1,
  MIN_CONTRACT_LENGTH: 6,
  MAX_CONTRACT_LENGTH: 84,
  MAX_CONTRACT_COMBINED_WITH_AGE_LENGTH: 144,
  MAX_CONTRACT_LENGTH_NINE_YEAR_RULE: 108,
  MAX_CONTRACT_LENGTH_UNDER_MIN_DEPOSIT: 60,
  MIN_DEPOSIT_PERCENTAGE: 0.1,
  DEFAULT_DEPOSIT_PERCENTAGE: 0.2,
  MIN_DEPOSIT_PERCENTAGE_FOR_OLD_VEHICLE: 0.2,
  CURRENCY_SUFFIX: ' kr.',
  MONTHS_SUFFIX: ' mánuðir',
  ERROR_INPUT_DELAY: 3000,
}
export const overviewCountUp = {
  DECIMAL: ',',
  DELAY: 0.5,
  DURATION: 1,
  SEPARATOR: '.',
}
export const APRConstants = {
  MIN_APR_VARIANCE: 0.002,
  MID_APR_VARIANCE: 0.004,
  MAX_APR_VARIANCE: 0.006,
  PERCENT_TOLERANCE: 0.03,
  MAX_ITERATIONS: 1000,
  MAX_STEP: 0.0001,
}
export const calculatorErrors = {
  MIN_PRICE_ERROR: `Lágmarkskaupverð er ${convertNumberToCurrency(calculatorConstants.MIN_PRICE_AMOUNT)}`,
  MAX_PRICE_ERROR: `Hámarkskaupverð er ${convertNumberToCurrency(calculatorConstants.MAX_PRICE_AMOUNT)}`,
  MIN_DEPOSIT_ERROR: `Lágmarksinnborgun er ${convertNumberToCurrency(
    calculatorConstants.MIN_DEPOSIT_AMOUNT
  )} eða 10% af kaupverði`,
  MIN_DEPOSIT__PERCENTAGE_ERROR: `Lágmarksinnborgun er 10% af kaupverði`,
  MAX_DEPOSIT_ERROR: `Hámarksinnborgun takmarkast við ${convertNumberToCurrency(
    calculatorConstants.MIN_LOAN_AMOUNT
  )} lágmarkslán`,
  MIN_CONTRACT_ERROR: `Lágmarkslengd láns er ${calculatorConstants.MIN_CONTRACT_LENGTH} mánuðir`,
  MAX_CONTRACT_ERROR: `Hámarkslengd láns er ${calculatorConstants.MAX_CONTRACT_LENGTH} mánuðir`,
}

export const IsDateStrings = {
  months: [
    'Janúar',
    'Febrúar',
    'Mars',
    'Apríl',
    'Maí',
    'Júní',
    'Júlí',
    'Ágúst',
    'September',
    'Október',
    'Nóvember',
    'Desember',
  ],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maí', 'Jún', 'Júl', 'Ágú', 'Sept', 'Okt', 'Nóv', 'Des'],
}

export enum FeeTypes {
  PaymentFee = 9, // Greiðslugjald
  RegistrationFee = 6, // Þinglýsingargjald
  ClosingFee = 4, // Lokagjald
}

export const routingPaths = {
  news: 'frettir',
  search: 'leit',
}
