import { ExtraStyle } from '../../types/Types'
import MainGridContainer from '../grid/MainGridContainer'

interface IProps {
  children: any
  sliceType?: string
  gridStyle?: ExtraStyle
}

const Slice: React.FC<IProps> = ({ sliceType, gridStyle, children }) => {
  return (
    <section className={sliceType}>
      <MainGridContainer extraStyle={gridStyle}>{children}</MainGridContainer>
    </section>
  )
}
export default Slice
