import { AlternateLanguage } from '@prismicio/client/types/documents'
import { RichTextBlock } from 'prismic-reactjs'
import { PrismicImageProps, PrismicLinkProps, PrismicTitle, IPrismicImage, IPrismicSlice } from './prismicBaseTypes'
import { Language } from './types/Types'

export enum PrismicDocumentTypes {
  Page = 'page',
  SubPage = 'subpage',
  News = 'news',
  NewsArticle = 'newsarticle',
}

export interface IFeaturedPagesLinks extends IPrismicSlice {
  items: {
    featured_page: PrismicLinkProps
  }[]
}

export interface IDocumentSliceLinks extends IPrismicSlice {
  items: {
    documentslice: PrismicLinkProps
  }[]
}

export interface IFeaturedPagesSlice extends IPrismicSlice {
  primary: {}
  items: {
    title: PrismicTitle[]
    description: RichTextBlock[]
    image: PrismicImageProps
    label: PrismicTitle[]
    link: PrismicLinkProps
  }[]
}
export interface IFAQCards extends IPrismicSlice {
  primary: {
    title: PrismicTitle[]
  }
  items: {
    question: PrismicTitle[]
    answer: RichTextBlock[]
  }[]
}

export interface IContactUsSlice extends IPrismicSlice {
  primary: {
    contact_us_title: RichTextBlock[]
    contact_us_body: RichTextBlock[]
    contact_us_successful_submission_text: RichTextBlock[]
    contact_us_successful_compliance_submission_text: RichTextBlock[]
    contact_us_failed_submission_text: RichTextBlock[]
  }
}

export interface IInterrestRates {
  type: number
  description: string
  percentage: number
  financingRatioTo: number
  financingRatioFrom: number
}
export interface ICalculatorCost {
  type: number
  description: string
  amount: number
}
export interface ICalculatorCommitmentFee {
  type: number
  description: string
  loanLengthMonthsFrom: number
  loanLengthMonthsTo: number
  percentage: number
}
export interface ICalculatorData {
  loan_data: {
    dueDateDay: number
    minDaysToFirstDueDate: number
    maxLoanLengthMonth: number
    minCommitmentFee: number
    loanType: number
    loanSubType: number
    loanTypeDescription: string
    commitmentFee: ICalculatorCommitmentFee[]
    cost: ICalculatorCost[]
    interest: IInterrestRates[]
  }
}
export interface IBreakdownSumValues {
  cost: number
  installment: number
  interest: number
  totalPaid: number
}
export interface ILoanTypesButtons {
  loan_type_id: number
  loan_subtype_id: number
  text: string
}
export interface ILoanTypesText {
  loan_type_id: number
  loan_subtype_id: number
  icon: TIconType
  text: string
}
export interface ICalculatorCard {
  title: [
    {
      text: string
      type: string
    }
  ]
  image: {
    alt: string
    url: string
  }
  loan_type_buttons: ILoanTypesButtons[]
  loan_types_text: ILoanTypesText[]
  tooltip_green: RichTextBlock[]
  calculator_data: ICalculatorData[]
}

export interface IProcessCards {
  title: string
  process: { description: RichTextBlock[]; icon: PrismicImageProps }[]
}

export interface IInterestRates {
  title: [
    {
      text: string
      type: string
    }
  ]
  image: {
    alt: string
    url: string
  }
  interest_rates: ICalculatorData[]
}

export type TDocumentSlicePrimary = IProcessCards | ICalculatorCard

export interface IImageCards {
  image_card_label: string
  image_card_title: string
  image_card_description: RichTextBlock[]
  image_card_list_columns: number
  image_card_url: PrismicLinkProps
  image_card_link_text?: string
  align_image_right: boolean
  image_card_image: IPrismicImage
}

export interface IImageCardSlice extends IPrismicSlice {
  primary: IImageCards
}

export interface IThreeColumnNewsCardSlice extends IPrismicSlice {
  primary: {
    overview_title: RichTextBlock[]
    number_of_articles: number
    link_text: string
  }
  newsArticlesPreview: IArticlePreview[]
}

export interface ITwoColumnCardsSlice extends IPrismicSlice {
  primary: {}
  items: {
    list_card_title: string
    list_card_description: RichTextBlock[]
    list_card_image: IPrismicImage
  }[]
}

export interface IDocumentSlice extends IPrismicSlice {
  primary: TDocumentSlicePrimary
}

export interface IThreeColumnIconCardsSlice extends IPrismicSlice {
  primary: {
    three_column_title: string
  }
  items: {
    three_column_card_title: string
    three_column_card_description: RichTextBlock[]
    three_column_card_icon: IPrismicImage
  }[]
}

export interface ITwoColumnInfoCardsSlice extends IPrismicSlice {
  primary: {
    two_column_info_title: string
  }
  items: {
    two_column_info_card_title: string
    two_column_info_card_description: RichTextBlock[]
  }[]
}

export interface ISearchSlice extends IPrismicSlice {
  primary: {
    search_id: PrismicTitle[]
    search_title: PrismicTitle[]
    search_placeholder: PrismicTitle[]
  }
}
export type TIconType = 'bubbles' | 'user' | 'calculator' | 'questionBubbles' | 'None' | 'greenLeaf'

export namespace IFooter {
  export interface IIconLinksSlice extends IPrismicSlice {
    items: { icon: TIconType; link: PrismicLinkProps; link_text: string }[]
  }

  export interface ILinksSlice extends IPrismicSlice {
    items: { link: PrismicLinkProps; link_text: string }[]
  }

  export type ISlice = IIconLinksSlice | ILinksSlice

  export interface IData {
    uid: string
    ssn: string
    phone_number: string
    address: string
    kvika_subsidiary_text: RichTextBlock[]
    email: string
    opening_hours: RichTextBlock[]
    facebook_link: PrismicLinkProps
    instagram_link: PrismicLinkProps
    body: ISlice[]
  }

  export interface IProps {
    data: IData
    type: string
    lang: Language
    alternate_languages: AlternateLanguage[]
  }
}
export namespace INavigation {
  export interface ILinks {
    link: PrismicLinkProps
    link_text: string
  }
  export interface IIconLinks extends IPrismicSlice {
    icon: TIconType
    link: PrismicLinkProps
    link_text: string
  }
  export interface IData {
    data: {
      links: ILinks[]
      body: any
      my_pages_link: PrismicLinkProps
      my_pages_link_text: string
    }
  }

  export interface IProps {
    data: IData
  }
}

export interface INotificationBanner {
  data: {
    title: string
    description: string
    link_to_page: PrismicLinkProps
    link_text_override: string
    visible: boolean
  }
}

export interface ICTACardSlice extends IPrismicSlice {
  primary: {
    card_title: string
    primary_button_link: PrismicLinkProps
    primary_button_text: string
    secondary_button_link: PrismicLinkProps
    secondary_button_text: string
    card_image: PrismicImageProps
  }
}

export interface IDepartmentInfoSlice extends IPrismicSlice {
  primary: {
    department_title: string
  }
  items: {
    employee_name: string
    job_description: string
    email: string
    work_phone: string
    cell_phone: string
  }[]
}

export interface IWysiwygSlice extends IPrismicSlice {
  primary: {
    title: string
    text: RichTextBlock[]
  }
  slice_type: string
}

export interface IIframeSlice extends IPrismicSlice {
  primary: {
    iframe_url: string
  }
  slice_type: string
}

export namespace IFrontPage {
  export type ISlice =
    | IDocumentSlice
    | IImageCardSlice
    | IFeaturedPagesSlice
    | ICTACardSlice
    | ITwoColumnCardsSlice
    | IThreeColumnIconCardsSlice
    | ITwoColumnInfoCardsSlice
    | IThreeColumnNewsCardSlice
    | IContactUsSlice

  export interface IData {
    title: PrismicTitle[]
    image: {
      alt: string
      url: string
    }
    description: RichTextBlock[]
    calculator_site: PrismicLinkProps
    hero_link: PrismicLinkProps
    hero_link_text: string
    body: ISlice[]
  }
  export interface IProps {
    data: IData
    lang: Language
  }
}

export interface IPageCardsSlice extends IPrismicSlice {
  primary: {}
  items: {
    card_title: string
    card_description: RichTextBlock[]
    link: PrismicLinkProps
    card_image: PrismicImageProps
  }[]
}

export namespace IPage {
  export type ISlice =
    | IDocumentSlice
    | IImageCardSlice
    | ITwoColumnCardsSlice
    | IThreeColumnIconCardsSlice
    | ITwoColumnInfoCardsSlice
    | IFAQCards
    | IDepartmentInfoSlice
    | IPageCardsSlice
    | IIframeSlice
    | ISearchSlice
    | IContactUsSlice

  export interface IData {
    title: PrismicTitle[]
    description: RichTextBlock[]
    image: PrismicImageProps
    button_text: string
    button_link: PrismicLinkProps
    button_scroll_section: string
    include_image_in_hero: boolean
    label: RichTextBlock[]

    body: ISlice[]
    meta_title?: string
    meta_description?: string
    noindex: boolean
    og_image?: PrismicImageProps
    og_title?: string
    og_description?: string
  }
  export interface IProps {
    data: IData
    type: string
    lang: Language
    uid: string
    tags?: string[]
  }
}

export namespace INews {
  export type ISlice =
    | IDocumentSlice
    | IImageCardSlice
    | ITwoColumnCardsSlice
    | IThreeColumnIconCardsSlice
    | ITwoColumnInfoCardsSlice
    | IFAQCards
    | IDepartmentInfoSlice
    | IPageCardsSlice
    | IIframeSlice

  export interface IData {
    title: PrismicTitle[]
    description: RichTextBlock[]
    image: PrismicImageProps
    button_text: string
    button_link: PrismicLinkProps
    button_scroll_section: string
    include_image_in_hero: boolean
    label: RichTextBlock[]

    body: ISlice[]
    meta_title?: string
    meta_description?: string
    noindex: boolean
  }
  export interface IProps {
    data: IData
    type: string
    lang: Language
    uid: string
    tags?: string[]
  }
}

export interface ITextCardSlice extends IPrismicSlice {
  items: {}[]
  primary: {
    title: string
    description: RichTextBlock[]
  }
}

export interface IServiceProvidersSlice extends IPrismicSlice {
  items: {
    car_type: string
    provider: RichTextBlock[]
    address: string
    phone: string
  }[]
  primary: { title: string }
}

export interface IArticlePreview {
  title: string
  short_description: string
  published_date: Date
  href: string
}

export namespace ISubPage {
  export type ISlice =
    | ITextCardSlice
    | IDocumentSlice
    | IImageCardSlice
    | ITwoColumnCardsSlice
    | IThreeColumnIconCardsSlice
    | ITwoColumnInfoCardsSlice
    | IFAQCards
    | IServiceProvidersSlice
    | IWysiwygSlice

  export interface IData {
    label: string
    title: string
    description: RichTextBlock[]
    body: ISlice[]
    meta_title?: string
    meta_description?: string
    noindex: boolean
    link_to_parent: PrismicLinkProps
    og_image?: PrismicImageProps
    og_title?: string
    og_description?: string
  }
  export interface IProps {
    data: IData
    type: string
    lang: Language
    uid: string
    tags?: string[]
  }
}

export namespace INewsArticle {
  export type ISlice =
    | ITextCardSlice
    | IDocumentSlice
    | IImageCardSlice
    | ITwoColumnCardsSlice
    | IThreeColumnIconCardsSlice
    | ITwoColumnInfoCardsSlice
    | IFAQCards
    | IServiceProvidersSlice
    | IWysiwygSlice

  export interface IData {
    title: RichTextBlock[]
    short_description: RichTextBlock[]
    article_body: RichTextBlock[]
    published_date: Date
    meta_title?: string
    meta_description?: string
    noindex: boolean
    og_image?: PrismicImageProps
  }
  export interface IProps {
    data: IData
    type: string
    lang: Language
    uid: string
    tags?: string[]
  }
}

export namespace IErrorPage {
  export interface IData {
    title: string
    description: RichTextBlock[]
    image: PrismicImageProps
  }

  export interface IProps {
    data: IData
    type: string
    lang: Language
    uid: string
    tags?: string[]
  }
}
