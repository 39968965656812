import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import { createLogger } from 'redux-logger';

import loanReducer from './loan';
/* uncomment to see the logs from the calculator  
and add this to line 32 Col 7 .concat(logger),
const logger = createLogger({
  
  collapsed: true,
});
*/
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  timeout: 0,
};

const persistedLoanReducer = persistReducer(persistConfig, loanReducer);

const store = configureStore({
  reducer: {
    loan: persistedLoanReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
