import Prismic from '@prismicio/client'
import { routingPaths } from '../data/Constants'
import { PrismicDocumentTypes } from '../prismicTypes'
import { Language } from '../types/Types'
import Client from './prismicHelpers'
export const PRISMIC_PAGE_SIZE = 100
export const getPages = async () => {
  const client = Client()
  const pages = await client.query(Prismic.Predicates.any('document.type', ['page', 'news']), {
    pageSize: PRISMIC_PAGE_SIZE,
  })

  return pages.results
}

export const getSubPages = async () => {
  const client = Client()
  const subPage = await client.query(Prismic.Predicates.any('document.type', ['subpage', 'newsarticle']), {
    fetch: [`${PrismicDocumentTypes.NewsArticle}.uid`, `${PrismicDocumentTypes.NewsArticle}.type`],
    pageSize: PRISMIC_PAGE_SIZE,
  })

  return subPage.results
}

export const getErrorData = async (previewDataRef: string | number | string[]) => {
  const client = Client()
  const [navigation, error, footer] = await Promise.all([
    client.getSingle('navigation', { ref: previewDataRef }),
    client.getSingle('error_page', { ref: previewDataRef }),
    client.getSingle('footer_v2', { ref: previewDataRef }),
  ])

  return {
    props: {
      navigation,
      error,
      footer,
    },
  }
}

export const getFrontPageData = async (previewDataRef: string | number | string[]) => {
  const client = Client()
  const [navigation, notificationbanner, frontpage, footer] = await Promise.all([
    client.getSingle('navigation', { ref: previewDataRef }),
    client.getSingle('notificationbanner', { ref: previewDataRef }),
    client.getSingle('frontpage', { ref: previewDataRef }),
    client.getSingle('footer_v2', { ref: previewDataRef }),
  ])
  return {
    props: {
      navigation,
      notificationbanner,
      frontpage,
      footer,
    },
  }
}

export const getNewsArticlePreviews = async (page: number) => {
  const client = Client()

  const articlePreviews = await client.query(Prismic.Predicates.at('document.type', PrismicDocumentTypes.NewsArticle), {
    fetch: [
      `${PrismicDocumentTypes.NewsArticle}.title`,
      `${PrismicDocumentTypes.NewsArticle}.short_description`,
      `${PrismicDocumentTypes.NewsArticle}.published_date`,
    ],
    pageSize: PRISMIC_PAGE_SIZE,
    page: page,
  })

  return articlePreviews.results
    .map((doc) => ({
      title: doc.data.title[0].text,
      short_description: doc.data.short_description[0].text,
      published_date: doc.data.published_date,
      href: `/${routingPaths.news}/${doc.uid}`,
    }))
    .sort((firstEl, secondEl) => {
      return firstEl.published_date < secondEl.published_date
        ? 1
        : firstEl.published_date > secondEl.published_date
        ? -1
        : 0
    })
}

export const getPageData = async (
  previewDataRef: string | number | string[],
  lang: Language,
  uid: string
): Promise<{ props: { navigation: any; page: any; footer: any } }> => {
  const client = Client()
  let fetchPagePromise

  switch (uid) {
    case routingPaths.news:
      fetchPagePromise = client.getSingle('news', { ref: previewDataRef, lang })
      break
    default:
      fetchPagePromise = client.getByUID('page', uid, { ref: previewDataRef, lang })
      break
  }

  const [navigation, page, footer] = await Promise.all([
    client.getSingle('navigation', { ref: previewDataRef }),
    fetchPagePromise,
    client.getSingle('footer_v2', { ref: previewDataRef }),
  ])

  return { props: { navigation, page, footer } }
}

export const getSubPageData = async (
  previewDataRef: string | number | string[],
  lang: Language,
  pageUID: string,
  subpageUID: string
): Promise<{ props: { navigation: any; subpage: any; footer: any } }> => {
  const client = Client()
  let fetchSubPageData

  switch (pageUID) {
    case routingPaths.news:
      fetchSubPageData = client.getByUID(PrismicDocumentTypes.NewsArticle, subpageUID, { ref: previewDataRef, lang })
      break

    default:
      fetchSubPageData = client.getByUID(PrismicDocumentTypes.SubPage, subpageUID, { ref: previewDataRef, lang })
      break
  }

  const [navigation, subpage, footer] = await Promise.all([
    client.getSingle('navigation', { ref: previewDataRef }),
    fetchSubPageData,
    client.getSingle('footer_v2', { ref: previewDataRef }),
  ])

  return { props: { navigation, subpage, footer } }
}

export const getDocumentsByIds = async (id: string[]) => {
  const client = Client()
  return client.query([Prismic.Predicates.in('document.id', id)])
}
