import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Language } from '../../types/Types'
import { updateErrorMessage } from '../../store/loan'
import { useDispatch } from 'react-redux'

// Track client-side page views with Segment
declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
  }
}

interface RoutingState {
  routeChanging: boolean
  handleRouteChange: (params: {
    url: string
    isNextLink?: boolean
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>
    locale?: Language
  }) => void
}

const RoutingContext = createContext<RoutingState>({
  routeChanging: false,
  handleRouteChange: () => undefined,
})

export const useRouteChange = () => useContext(RoutingContext)

const RoutingProvider: FunctionComponent = ({ children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const locale = router.locale as Language
  const [routeChanging, setRouteChanging] = useState(false)
  /*
    routeChangingSamePage was implemented because current setup caused a loop.
    If the user clicked on a result in the Search which was on the same page.
    For example: currentURL is /bilalan and the user clicked on a searchItem which were "/bilalan#two_column_info_cards-4" 
    The routeChanging causes a loop between scrolling to 0,0 and to the component.

    */
  const [routeChangingSamePage, setRouteChangingSamePage] = useState(false)
  const [urlToPush, setUrlToPush] = useState('')
  const [routeLocale, setRouteLocale] = useState(locale)

  const handleRouteChange = (params: {
    url: string
    event?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>
    isNextLink?: boolean
    locale?: Language
  }) => {
    const { event, url, isNextLink = false, locale = routeLocale } = params
    if (event && (event.metaKey || !isNextLink)) {
      // If command click let the browser do its work
      return
    }

    event?.preventDefault()

    /*
      Variables for search hits within same page
    */
    const currentPath =
      router.asPath.indexOf('#') > 0 ? router.asPath.substr(0, router.asPath.indexOf('#')) : router.asPath

    const nextPath = url.substr(0, url.indexOf('#'))
    const componentSearch = url.includes('#')
    if (isNextLink) {
      router.prefetch(url)
    }
    if (currentPath === nextPath && componentSearch) {
      setRouteChanging(false)
      setRouteChangingSamePage(true)
    } else {
      setRouteChanging(true)
    }

    setRouteLocale(locale)
    setUrlToPush(url)
  }

  useEffect(() => {
    let routingPush: number

    if (routeChanging) {
      routingPush = window.setTimeout(
        () => router.push(urlToPush, urlToPush, { locale: routeLocale }).then(() => window.scrollTo(0, 0)),
        320
      )
    } else if (routeChangingSamePage) {
      routingPush = window.setTimeout(() => router.push(urlToPush, urlToPush, { locale: routeLocale }), 320)
      setRouteChangingSamePage(false)
    }
    return () => clearTimeout(routingPush)
  }, [routeChanging, urlToPush, router, routeLocale])

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      dispatch(updateErrorMessage(''))
      setRouteChanging(false)
      if (window.analytics) {
        window.analytics.page(urlToPush)
      }
    }
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => router.events.off('routeChangeComplete', handleRouteChangeComplete)
  }, [router])

  return <RoutingContext.Provider value={{ routeChanging, handleRouteChange }}>{children}</RoutingContext.Provider>
}

export default RoutingProvider
