import Image from 'next/image'
import styled, { css } from 'styled-components'
import { Translations } from '../../data/Translations'
import { IFooter } from '../../prismicTypes'

import IconFacebook from '../../public/svg/IconFacebook'
import IconInstagram from '../../public/svg/IconInstagram'
import KvikaLogo from '../../public/svg/KvikaLogo'
import Typography from '../../styles/typography'
import PrismicIconLink from '../Links/PrismicIconLink'
import PrismicLink from '../Links/PrismicLink'
import PrismicRichText from '../PrismicRichText'
import Slice from '../Slice'

interface IProps {
  footer: IFooter.IProps
}

const Footer: React.FunctionComponent<IProps> = ({ footer }: IProps) => {
  const translations = Translations.is

  const {
    opening_hours,
    ssn,
    email,
    address,
    phone_number,
    facebook_link,
    instagram_link,
    body,
    kvika_subsidiary_text,
  } = footer.data

  const iconLinks = body.find((slice) => slice.slice_type === 'icon_links') as IFooter.IIconLinksSlice
  const links = body.find((slice) => slice.slice_type === 'links') as IFooter.ILinksSlice

  return (
    <SCFooter>
      <Slice>
        <SCGridContainer>
          <SCMobileLogo>
            <Image src="/svg/lykill_logo.svg" width={106} height={32} alt="Logo" />
          </SCMobileLogo>
          <SCIconLinks>
            {iconLinks.items.map((link, index) => (
              <PrismicIconLink
                icon={link.icon}
                text={link.link_text}
                link={link.link}
                key={`${link.link_text}${index}`}
              />
            ))}
          </SCIconLinks>
          <SCOpeningHours>
            <SCOpeningHoursTitle>{translations.footer.openingHours}</SCOpeningHoursTitle>
            <SCOpeningHoursParagraph>
              <PrismicRichText extraParagraphStyle={Typography.pSmall} richText={opening_hours} />
              <PrismicRichText extraParagraphStyle={Typography.pSmall} richText={kvika_subsidiary_text} />
              <a href="https://www.kvika.is">
                <KvikaLogo />
              </a>
            </SCOpeningHoursParagraph>
          </SCOpeningHours>
          <SCLinks>
            {links.items.map((link, index) => (
              <SCLinkWrapper key={`${link.link_text}${index}`}>
                <PrismicLink extraStyle={SCLink} link={link.link}>
                  {link.link_text}
                </PrismicLink>
              </SCLinkWrapper>
            ))}
          </SCLinks>
          <SCGeneralInfo>
            <SCLogo>
              <Image src="/svg/lykill_logo.svg" width={106} height={32} alt="Logo" />
            </SCLogo>
            <SCGeneralInfoParagraph>
              <a href={`tel:${phone_number}`}>{phone_number}</a>
            </SCGeneralInfoParagraph>
            <SCGeneralInfoParagraph>
              <SCGeneralInfoLink href={`http://maps.google.com/?q=${address}`}>{address}</SCGeneralInfoLink>
            </SCGeneralInfoParagraph>

            <SCGeneralInfoParagraph>
              <SCGeneralInfoLink href={`mailto:${email}`}>{email}</SCGeneralInfoLink>
            </SCGeneralInfoParagraph>
            <SCGeneralInfoParagraph>kt. {ssn}</SCGeneralInfoParagraph>
            <SCGeneralInfoParagraph>{translations.footer.bankAccountNumber}</SCGeneralInfoParagraph>
          </SCGeneralInfo>
          <SCSocialMediaIcons>
            <PrismicLink extraStyle={SCIconButton} link={facebook_link}>
              <IconFacebook />
            </PrismicLink>
            <PrismicLink link={instagram_link}>
              <IconInstagram />
            </PrismicLink>
          </SCSocialMediaIcons>
        </SCGridContainer>
      </Slice>
    </SCFooter>
  )
}

export default Footer

const SCFooter = styled.footer`
  margin-top: 6.5rem;
  margin-bottom: 0.75rem;
`

const SCMobileLogo = styled.span`
  ${({ theme: { breakpoints } }) => css`
    margin-bottom: 3.5rem;
    grid-area: logo;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: none;
    }
  `}
`

const SCLogo = styled.span`
  ${({ theme: { breakpoints } }) => css`
    display: none;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: inline-block;
      align-self: center;
      margin-right: 3rem;
    }
  `}
`

const SCGridContainer = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: grid;
    grid-column: 1 / -1;
    grid-template-areas:
      'logo'
      'il'
      'oh'
      'l'
      'gi'
      'sm';
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-areas:
        'il l'
        'oh sm'
        'gi gi';
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-areas:
        'il il oh oh l l'
        'gi gi gi gi gi sm';
    }
  `}
`

const SCOpeningHours = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-area: oh;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.8125rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: 0;
    }
  `}
`

const SCOpeningHoursTitle = styled.span`
  ${Typography.pSmall};
  font-weight: 900;
`

const SCOpeningHoursParagraph = styled.div`
  > p {
    ${Typography.pSmall};
  }
`

const SCIconLinks = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-area: il;
    display: flex;
    flex-direction: column;
    margin-bottom: 2.8125rem;

    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-botttom: 4.875rem;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: -0.75rem;
    }
  `}
`

const SCLinks = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-area: l;
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-bottom: 3.5rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-bottom: 0;
      gap: 0.5rem 4rem;
      justify-self: flex-end;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      gap: 0.5rem 8rem;
      margin-top: 0;
    }
  `}
`

const SCLinkWrapper = styled.div`
  margin-bottom: 1rem;
`

const SCLink = css`
  ${({ theme: { colors } }) => css`
    ${Typography.pSmall};
    border-bottom: 0.125rem solid ${colors.white};
    font-weight: bold;
    margin-bottom: 1rem;
    transition: border-bottom 320ms ease;

    &:hover {
      border-bottom: 0.125rem solid ${colors.blue200};
    }
  `}
`

const SCGeneralInfo = styled.div`
  ${({ theme: { breakpoints } }) => css`
    grid-area: gi;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      display: flex;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: 4rem;
    }
  `}
`

const SCGeneralInfoLink = styled.a`
  ${({ theme: { colors } }) => css`
    ${Typography.pSmall};
    border-bottom: 0.125rem solid ${colors.white};
    font-weight: 600;
    margin-bottom: 1rem;
    transition: border-bottom 320ms ease;

    &:hover {
      border-bottom: 0.125rem solid ${colors.blue200};
    }
  `}
`

const SCGeneralInfoParagraph = styled.p`
  ${({ theme: { breakpoints } }) => css`
    margin-right: 3rem;
    &,
    a {
      ${Typography.pSmall};
      font-weight: 600;
    }
    @media screen and (max-width: ${breakpoints.desktopMax}) {
      margin-right: 2rem;
    }
    @media screen and (max-width: ${breakpoints.desktopMidPlus}) {
      font-size: 0.75rem;
      &,
      a {
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: ${breakpoints.desktopMin}) {
      &,
      a {
        font-size: 0.735rem;
      }
    }
  `}
`

const SCIconButton = css`
  margin-right: 1rem;
`

const SCSocialMediaIcons = styled.div`
  ${({ theme: { breakpoints } }) => css`
    justify-self: flex-start;
    grid-area: sm;
    margin-top: 2rem;
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      margin-top: 0;
      align-self: center;
      justify-self: flex-end;
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      margin-top: 4rem;
    }
  `}
`
