import Link from 'next/link'
import Image from 'next/image'

import { useMediaQuery } from '@mui/material'
import { mediaQueries } from '../../data/Constants'
import MobileMenu from './MobileMenu'
import MyPagesButton from './MyPagesButton'
import styled, { css } from 'styled-components'
import Typography from '../../styles/typography'
import MainGridContainer from '../grid/MainGridContainer'
import { Gutters } from '../../types/Types'
import GridItem from '../grid/GridItem'
import { GridStyles } from '../../styles/gridStyles'
import { INavigation } from '../../prismicTypes'
import PrismicLink from '../Links/PrismicLink'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import { useState } from 'react'
import SearchHeader from '../Search/SearchHeader'

interface IProps {
  links: INavigation.ILinks[]
  iconLinks: INavigation.IIconLinks[]
  my_pages_link: PrismicLinkProps
  my_pages_link_text: string
}
export const toggleStickOnBody = (type: 'add' | 'remove') => {
  if (type === 'add') {
    document.body.classList.add('stick')
    document.documentElement.style.overflowY = 'scroll'
    document.documentElement.style.overflowX = 'hidden'
  } else {
    document.body.classList.remove('stick')
    document.documentElement.style.overflowY = 'initial'
    document.documentElement.style.overflowX = 'initial'
  }
}

const Navigation: React.FC<IProps> = ({ links, iconLinks, my_pages_link, my_pages_link_text }: IProps) => {
  const isTabletOrSmaller = useMediaQuery(mediaQueries.isTabletOrSmaller)
  const [isSearchOpen, setSearchOpen] = useState(false)

  return (
    <MainGridContainer extraStyle={SCGrid} gutters={Gutters.None}>
      <GridItem extraStyle={GridStyles.gridColumnsAll}>
        {isTabletOrSmaller ? (
          <>
            <MobileMenu links={links} iconLinks={iconLinks} />
            <SCSpacer />
          </>
        ) : (
          <SCNavigation>
            <Link href="/">
              <SCLogo>
                <Image src="/svg/lykill_logo.svg" width={119} height={36} alt="Logo" />
              </SCLogo>
            </Link>
            <SCLinks isSearchOpen={isSearchOpen}>
              {links.map((link) => (
                <PrismicLink extraStyle={SCLink} key={link.link.id} link={link.link}>
                  {link.link_text}
                </PrismicLink>
              ))}
            </SCLinks>
            <SearchHeader
              onClose={() => {
                toggleStickOnBody('remove')
                setSearchOpen(false)
              }}
              onOpen={() => {
                toggleStickOnBody('add')
                setSearchOpen(true)
              }}
            />
            <MyPagesButton link={my_pages_link} text={my_pages_link_text} />
          </SCNavigation>
        )}
      </GridItem>
    </MainGridContainer>
  )
}

export default Navigation

const SCGrid = css`
  margin: 0 2rem;
`

const SCLogo = styled.div`
  margin-right: 3.5rem;
  cursor: pointer;
  min-width: 5rem;
`

const SCNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`

const SCLinks = styled.div<{ isSearchOpen: boolean }>`
  ${({ theme: { breakpoints }, isSearchOpen }) => css`
    flex: auto;
    display: inline-flex;
    ${isSearchOpen &&
    css`
      @media screen and (max-width: ${breakpoints.desktopMid}) {
        margin-right: 1rem;
        a {
          margin-right: 1.5rem;
        }
      }
      @media screen and (max-width: ${breakpoints.desktopMinPlus}) {
        margin-right: 0.5rem;
        a {
          margin-right: 1rem;
        }
      }
    `}
  `}
`

const SCLink = css`
  ${({ theme: { colors, breakpoints } }) => css`
    ${Typography.buttonText};
    margin-right: 1rem;
    color: ${colors.blue400};
    border-bottom: 0.125rem solid ${colors.white};
    transition: border-bottom 320ms ease;
    align-self: center;
    @media screen and (min-width: ${breakpoints.tabletMax}) {
      &:hover {
        cursor: pointer;
        border-bottom: 0.125rem solid ${colors.blue200};
      }
      margin-right: 3.5rem;
    }
  `}
`

const SCSpacer = styled.div`
  height: 6.75rem;
`
