import { theme } from '../../styles/globalStyles'
const IconInstagram: React.FC = () => {
  return (
    <svg width="20" height="20" fill="none">
      <g clipPath="url(#clip0)" fill={theme.colors.blue400}>
        <path d="M10 1.802c2.67 0 2.986.01 4.04.058.976.045 1.505.207 1.858.344.434.16.827.416 1.15.748.332.323.587.716.748 1.15.137.353.3.882.344 1.857.048 1.055.058 1.37.058 4.041 0 2.67-.01 2.986-.058 4.04-.045.976-.207 1.505-.344 1.858a3.312 3.312 0 01-1.898 1.898c-.353.137-.882.3-1.857.344-1.054.048-1.37.058-4.041.058-2.67 0-2.987-.01-4.04-.058-.976-.045-1.505-.207-1.858-.344a3.097 3.097 0 01-1.15-.748 3.097 3.097 0 01-.748-1.15c-.136-.353-.3-.882-.344-1.857-.048-1.055-.058-1.37-.058-4.041 0-2.67.01-2.986.058-4.04.045-.976.208-1.505.344-1.858.16-.434.416-.827.748-1.15a3.098 3.098 0 011.15-.748c.353-.137.882-.3 1.857-.344 1.055-.048 1.37-.058 4.041-.058zM10 0C7.284 0 6.944.012 5.877.06 4.813.11 4.086.278 3.45.525a4.902 4.902 0 00-1.772 1.153A4.902 4.902 0 00.525 3.45C.278 4.086.109 4.813.06 5.877.012 6.944 0 7.284 0 10s.012 3.056.06 4.123c.049 1.064.218 1.791.465 2.427a4.902 4.902 0 001.153 1.772c.5.508 1.104.902 1.772 1.153.636.247 1.363.416 2.427.465 1.067.048 1.407.06 4.123.06s3.056-.012 4.123-.06c1.064-.049 1.791-.218 2.427-.465a5.113 5.113 0 002.925-2.925c.247-.636.416-1.363.465-2.427.048-1.067.06-1.407.06-4.123s-.012-3.056-.06-4.123c-.049-1.064-.218-1.791-.465-2.427a4.9 4.9 0 00-1.153-1.772A4.901 4.901 0 0016.55.525C15.914.278 15.187.109 14.123.06 13.056.012 12.716 0 10 0z" />
        <path d="M10 4.863a5.135 5.135 0 100 10.27 5.135 5.135 0 000-10.27zm0 8.469a3.333 3.333 0 110-6.666 3.333 3.333 0 010 6.666zM15.338 5.865a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill={theme.colors.white} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconInstagram
