import { theme } from '../../styles/globalStyles'

const IconBubbles: React.FC = () => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.258 25.443a5.722 5.722 0 10-5.721-5.722M8.264 25.448c-.861 0-1.677-.19-2.41-.53l-3.52.748.736-3.528a5.718 5.718 0 01-.534-2.417"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.65 14.03A7.72 7.72 0 017 10.931c0-4.686 4.198-8.447 9.333-8.447 5.136 0 9.334 3.76 9.334 8.447 0 2.77-1.473 5.208-3.733 6.746.002.882 0 2.07 0 3.319l-3.663-1.804a10.275 10.275 0 01-4.326-.096"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconBubbles
