import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { IFooter, INavigation, INotificationBanner } from '../prismicTypes'
import Footer from './footer'

import Navigation from './navigation/Navigation'
import NotificationBanner from './NotificationBanner'
import { useRouteChange } from './RoutingProvider'

type Props = {
  footer: IFooter.IProps
  navigation: INavigation.IData
  notificationBanner?: INotificationBanner
  children?: ReactNode
}

const Layout = ({
  children,
  footer,
  notificationBanner,
  navigation: {
    data: { links, body, my_pages_link, my_pages_link_text },
  },
}: Props) => {
  const { routeChanging } = useRouteChange()
  return (
    <>
      <Navigation
        links={links}
        iconLinks={body[0].items}
        my_pages_link={my_pages_link}
        my_pages_link_text={my_pages_link_text}
      />
      <NotificationBanner banner={notificationBanner} />
      <SCWrapper routeChanging={routeChanging}>
        {children}
        <Footer footer={footer} />
      </SCWrapper>
    </>
  )
}

export default Layout

const SCWrapper = styled.div<{ routeChanging: boolean }>`
  ${({ routeChanging }) => css`
    transition: all 320ms ease;
    opacity: ${routeChanging ? 0 : 1};
  `}
`
