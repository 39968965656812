import React, { useRef, SyntheticEvent } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import styled, { css } from 'styled-components'
import { Translations } from '../../../data/Translations'
import IconSearch from '../../../public/svg/IconSearch'
import Typography from '../../../styles/typography'
interface IProps {
  id: string
  refine: (filter: string) => void
  isExpanded: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  onEnterInInput: (event: SyntheticEvent | React.KeyboardEvent<HTMLElement>) => void
  value: string
}

const SearchBox = ({ id, refine, isExpanded, value, onClick, onEnterInInput }: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { searchEngine } = Translations.is

  return (
    <SForm
      role="search"
      isExpanded={isExpanded}
      onClick={(e) => {
        onClick(e)
        inputRef.current?.focus()
      }}
    >
      <IconSearch />
      <SInputWrapper>
        <SInput
          id={id}
          ref={inputRef}
          type="search"
          tabIndex={isExpanded ? 0 : -1}
          isExpanded={isExpanded}
          value={value}
          onChange={(event) => refine(event.currentTarget.value)}
          placeholder={searchEngine.searchPlaceholder}
          onKeyDown={(e) => {
            if (e.nativeEvent.key === 'Enter') {
              onEnterInInput(e)
            }
          }}
        />
        <SCButtonText isExpanded={isExpanded}>{searchEngine.search}</SCButtonText>
      </SInputWrapper>
    </SForm>
  )
}

const SForm = styled.div<{ isExpanded: boolean }>`
  ${({ theme: { breakpoints, colors }, isExpanded }) => css`
    display: flex;
    width: 6rem;
    visibility: hidden;
    margin-top: -0.3125rem;
    padding: 0 0.75rem;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: width 320ms ease, background-color 0.2s;

    @media (min-width: ${breakpoints.tabletMin}) {
      visibility: visible;
      width: 5rem;
    }

    ${isExpanded &&
    css`
      visibility: visible;
      align-items: center;
      height: 2.25rem;
      margin-top: 0rem;
      width: calc(100vw - 2rem);
      background-color: ${colors.orange400};
      cursor: text;

      @media (min-width: ${breakpoints.tabletMin}) {
        width: calc(400px - 5vw);
      }
      @media (min-width: ${breakpoints.desktopMin}) {
        width: 15rem;
      }
      @media (min-width: ${breakpoints.desktopMidPlus}) {
        width: 22rem;
      }
      @media (min-width: ${breakpoints.desktopMax}) {
        width: 31.5rem;
      }
    `}

    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  `}
`

const SInputWrapper = styled.div`
  position: relative;
  width: 100%;
`

const SInput = styled.input<{ isExpanded: boolean }>`
  ${({ theme: { colors }, isExpanded }) => css`
    position: absolute;
    width: 0px;
    border: none;
    background: none;
    color: ${colors.blue400};
    caret-color: ${colors.blue400};
    outline: none;
    -webkit-appearance: none;

    ${isExpanded &&
    css`
      position: relative;
      width: calc(100% - 0.5rem);
      padding-left: 1rem;
      margin-top: -0.125rem;
      transition: width 320ms ease;
    `}
  `}
`
const SCButtonText = styled.span<{ isExpanded: boolean }>`
  ${({ isExpanded }) => css`
    ${Typography.buttonText};
    font-size: 0.8125rem;
    ${isExpanded &&
    css`
      display: none;
    `}
  `}
`
export default connectSearchBox(SearchBox)
