import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { ExtraStyle } from '../../types/Types'

interface Props {
  extraStyle?: ExtraStyle
  children: React.ReactNode
}

const GridContainer: FunctionComponent<Props> = ({ extraStyle, children }) => {
  return (
    <SCGridContainer extraStyle={extraStyle}>
      <>{children}</>
    </SCGridContainer>
  )
}

export default GridContainer

const SCGridContainer = styled.div<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle, theme: { breakpoints } }) => css`
    grid-area: content;
    display: grid;
    position: relative;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(2, [col] 1fr);
    @media screen and (min-width: ${breakpoints.tabletMin}) {
      grid-template-columns: repeat(6, [col] 1fr);
    }
    @media screen and (min-width: ${breakpoints.desktopMin}) {
      grid-template-columns: repeat(12, [col] 1fr);
    }
    ${extraStyle};
  `}
`
