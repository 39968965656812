import styled, { css } from 'styled-components'

import Typography from '../../styles/typography'
import { ExtraStyle } from '../../types/Types'

interface IProps {
  children: any
  extraStyle?: ExtraStyle
}

const Paragraph: React.FC<IProps> = ({ extraStyle, children }) => {
  return <SCParagraph extraStyle={extraStyle}>{children}</SCParagraph>
}

export default Paragraph

const SCParagraph = styled.p<{ extraStyle?: ExtraStyle }>`
  ${({ extraStyle }) => css`
    ${Typography.pBody}
    ${extraStyle}
  `}
`
