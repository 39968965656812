import { theme } from '../../styles/globalStyles'

const IconUser: React.FC = () => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.887 5.279a4.083 4.083 0 11-5.775 5.775 4.083 4.083 0 015.775-5.775"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M4.667 21.583v1.167c0 .643.522 1.166 1.166 1.166h16.334c.644 0 1.166-.523 1.166-1.166v-1.167c0-3.53-4.61-5.824-9.333-5.824s-9.333 2.293-9.333 5.824z"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconUser
