import { theme } from '../../styles/globalStyles'

const IconCalculator: React.FC = () => {
  return (
    <svg width="28" height="28" fill="none" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.334 17.385v6.653c.002.902 1.83 1.634 4.085 1.634 2.256 0 4.083-.732 4.084-1.634v-6.653"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.336 20.71c0 .902 1.828 1.634 4.084 1.634 2.256 0 4.085-.732 4.085-1.634M6.997 12.833h1.167M11.666 12.833h1.167M6.997 16.333h1.167M11.666 16.333h1.167M6.997 12.833h1.167M11.666 12.833h1.167M6.997 19.837h1.167M11.666 19.837h1.167M6.997 19.837h1.167M11.666 19.837h1.167M21.003 9.333H3.496M12.833 23.336H5.83A2.334 2.334 0 013.496 21V5.828A2.334 2.334 0 015.83 3.494h12.839a2.334 2.334 0 012.334 2.334v7.003M6.997 12.833h1.167M11.666 12.833h1.167M16.334 12.833h1.168"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.336 17.383c0 .902 1.828 1.634 4.084 1.634 2.256 0 4.083-.732 4.083-1.634 0-.903-1.83-1.634-4.084-1.634-2.255 0-4.083.732-4.085 1.634"
        stroke={theme.colors.blue400}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconCalculator
