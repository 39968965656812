import { routingPaths } from '../data/Constants'
import {
  IDepartmentInfoSlice,
  IFAQCards,
  IFrontPage,
  IImageCardSlice,
  INewsArticle,
  IPage,
  IServiceProvidersSlice,
  ISubPage,
  ITextCardSlice,
  IThreeColumnIconCardsSlice,
  ITwoColumnCardsSlice,
  ITwoColumnInfoCardsSlice,
  IWysiwygSlice,
} from '../prismicTypes'
import { Language } from '../types/Types'
import { replaceDoubleHyphen } from './helpers'

export interface IAlgoliaObject {
  objectID: string
  type: string
  title?: string
  searchableTitle?: string
  description?: string
  searchableDescription?: string
  image?: string
  isFeatured?: boolean
  slug: string
}

interface ISliceData {
  title: string
  searchableTitle: string
  description: string
  searchableDescription: string
  sliceType: string
  itemIndex?: number
}

export const algoliaIndexName = process.env.VERCEL_ENV === 'development' ? 'test-lykill.is' : 'prod-lykill.is'

const checkAndCombineStrings = (checkString: string | undefined, addToString: string): string => {
  if (checkString == undefined || checkString == '' || checkString == null) {
    return addToString
  } else {
    return addToString + ' ' + checkString
  }
}
const emptyTitleCheck = (title: string, label: string, meta_title: string | undefined): string => {
  if (title != undefined && title != '' && title != null) {
    return title
  } else if (meta_title != undefined && meta_title != '' && meta_title != null) {
    return meta_title + '' //since it "could be" undefined
  } else if (label != undefined || label != '' || label != null) {
    return label
  } else {
    return ''
  }
}
const emptyDescriptionCheck = (description: string, meta_description: string | undefined): string => {
  if (description != undefined && description != '' && description != null) {
    return description
  } else if (meta_description != undefined && meta_description != '' && meta_description != null) {
    return meta_description + '' //since it "could be" undefined
  } else {
    return ''
  }
}
export const getPageResults = (template: IPage.IProps[]): IAlgoliaObject[] => {
  return template.flatMap(({ data, uid, lang }) => {
    const algoliaTitle = data.title.map(({ text }) => text).join('') ?? ''
    const algoliaLabel = data.label.map(({ text }) => text).join('') ?? ''
    const algoliaDescription = data.description.map(({ text }) => text).join('') ?? ''
    const algoliaImage = data.image?.url ?? ''
    let algoliaSearchableTitle = ''
    let algoliaSearchableDescription = ''
    //SearchableTitle functions
    algoliaSearchableTitle = checkAndCombineStrings(algoliaTitle, algoliaSearchableTitle)
    algoliaSearchableTitle = checkAndCombineStrings(data.meta_title, algoliaSearchableTitle)
    algoliaSearchableDescription = checkAndCombineStrings(data.meta_description, algoliaSearchableDescription)
    algoliaSearchableDescription = checkAndCombineStrings(algoliaDescription, algoliaSearchableDescription)
    if (algoliaLabel != null) {
      algoliaSearchableDescription += ' ' + replaceDoubleHyphen(algoliaLabel, '')
    }
    return !data.noindex
      ? [
          {
            objectID: `${lang}-page-${uid}`,
            type: 'page',
            title: `${replaceDoubleHyphen(emptyTitleCheck(algoliaTitle, algoliaLabel, data.meta_title), '')}`,
            searchableTitle: algoliaSearchableTitle,
            description: `${emptyDescriptionCheck(algoliaDescription, data.meta_description)}`,
            searchableDescription: algoliaSearchableDescription,
            image: algoliaImage,
            isFeatured: true,
            slug: `/${uid}`,
          },
        ]
      : []
  })
}
export const getFrontPageResults = (template: IFrontPage.IProps[]): IAlgoliaObject[] => {
  return template.flatMap(({ data, lang }) => {
    const algoliaTitle = data.title.map(({ text }) => text).join('') ?? ''
    const algoliaDescription = data.description.map(({ text }) => text).join('') ?? ''
    const algoliaImage = data.image?.url ?? ''
    let algoliaSearchableTitle = ''
    let algoliaSearchableDescription = ''
    //SearchableTitle functions
    algoliaSearchableTitle = checkAndCombineStrings(algoliaTitle, algoliaSearchableTitle)
    algoliaSearchableDescription = checkAndCombineStrings(algoliaDescription, algoliaSearchableDescription)

    return [
      {
        objectID: `${lang}-page-frontpage`,
        type: 'page',
        title: `${replaceDoubleHyphen(algoliaTitle, '')}`,
        searchableTitle: algoliaSearchableTitle,
        description: algoliaDescription,
        searchableDescription: algoliaSearchableDescription,
        image: algoliaImage,
        isFeatured: true,
        slug: `/`,
      },
    ]
  })
}
export const getSubPageResults = (template: ISubPage.IProps[]): IAlgoliaObject[] => {
  return template.flatMap(({ data, uid, lang }) => {
    const subPageParentUID = data.link_to_parent.uid
    const subPageDescription = data.description.map(({ text }) => text).join('') ?? ''
    let algoliaSearchableTitle = ''
    let algoliaSearchableDescription = ''
    //SearchableTitle functions
    algoliaSearchableTitle = checkAndCombineStrings(data.title, algoliaSearchableTitle)
    algoliaSearchableTitle = checkAndCombineStrings(data.meta_title, algoliaSearchableTitle)
    algoliaSearchableDescription = checkAndCombineStrings(data.meta_description, algoliaSearchableDescription)
    algoliaSearchableDescription = checkAndCombineStrings(subPageDescription, algoliaSearchableDescription)
    if (data.label != null) {
      algoliaSearchableDescription += ' ' + replaceDoubleHyphen(data.label, '')
    }
    //objectID: `page-${uid}-${index}`, Taken out since duplicates where being made.
    return !data.noindex
      ? [
          {
            objectID: `${lang}-subpage-${subPageParentUID}-${uid}`,
            type: 'subpage',
            title: `${replaceDoubleHyphen(emptyTitleCheck(data.title, data.label, data.meta_title), '')}`,
            searchableTitle: algoliaSearchableTitle,
            description: `${emptyDescriptionCheck(subPageDescription, data.meta_description)}`,
            searchableDescription: algoliaSearchableDescription,
            image: '',
            isFeatured: true,
            slug: `/${subPageParentUID}/${uid}`,
          },
        ]
      : []
  })
}

export const getFrontPageSliceResults = (template: IFrontPage.IProps[]): IAlgoliaObject[] => {
  // per Page results
  return template.flatMap(({ data, lang }) => {
    let sliceResults: IAlgoliaObject[]
    let sliceData: ISliceData[]
    sliceData = getSliceData(data.body)
    sliceResults = sliceData.flatMap((slice, index) => {
      return slice.description != '' && slice.title != ''
        ? [
            {
              objectID: `${lang}-slice-frontpage-${slice.sliceType}-${index}`,
              type: 'Slice',
              title: slice.title,
              searchableTitle: slice.searchableTitle,
              description: slice.description,
              searchableDescription: slice.searchableDescription,
              image: '',
              isFeatured: false,
              slug: `/#${slice.sliceType}-${index}`,
            },
          ]
        : []
    })
    return sliceResults
  })
}
export const getPageSliceResults = (template: IPage.IProps[]): IAlgoliaObject[] => {
  // per Page results
  return template.flatMap(({ data, uid, lang }) => {
    return getSinglePageSliceResults(data, lang, uid)
  })
}

export const getSubPageSliceResults = (template: ISubPage.IProps[]): IAlgoliaObject[] => {
  // per Page results
  return template.flatMap(({ data, uid, lang }) => {
    const slug = `${data.link_to_parent.uid}/${uid}`
    return getSinglePageSliceResults(data, lang, slug)
  })
}

export const getSinglePageSliceResults = (
  data: IPage.IData | ISubPage.IData,
  lang: Language,
  url: string
): IAlgoliaObject[] => {
  let sliceResults: IAlgoliaObject[]
  let sliceData: ISliceData[]
  sliceData = getSliceData(data.body)
  //SliceCounter is instead of index since FAQ cards count each QA as an slice.
  let sliceCounter = 0
  sliceResults = sliceData.flatMap((slice) => {
    let slug = url
    let objectSliceId = ''
    if (slice.itemIndex == undefined) {
      objectSliceId = `${lang}-slice-${slug}-${slice.sliceType}-${sliceCounter}`
      slug += `#${slice.sliceType}-${sliceCounter}`
      sliceCounter++
    } else {
      objectSliceId = `${lang}-slice-${slug}-${slice.sliceType}-${sliceCounter}-${slice.itemIndex}`
      slug += `#${slice.sliceType}-${sliceCounter}--${slice.itemIndex}`
    }
    return !data.noindex && slice.description != '' && slice.title != ''
      ? [
          {
            objectID: objectSliceId,
            type: 'Slice',
            title: slice.title,
            searchableTitle: slice.searchableTitle,
            description: slice.description,
            searchableDescription: slice.searchableDescription,
            image: '',
            isFeatured: false,
            slug: `/${slug}`,
          },
        ]
      : []
  })
  return sliceResults
}
export const getNewsArticleResults = (template: INewsArticle.IProps[]): IAlgoliaObject[] => {
  return template.flatMap(({ data, uid, lang }) => {
    const newsParentUID = routingPaths.news
    const newsArticleTitle = data.title.map(({ text }) => text).join('') ?? ''
    const newsArticleDescription = data.article_body.map(({ text }) => text).join('') ?? ''
    const newsArticleShortDescription = data.short_description.map(({ text }) => text).join('') ?? ''
    let algoliaSearchableTitle = ''
    let algoliaSearchableDescription = ''
    //SearchableTitle functions
    algoliaSearchableTitle += newsArticleTitle + ' ' + data.published_date + ' ' + data.meta_title
    algoliaSearchableTitle = checkAndCombineStrings(data.meta_title, algoliaSearchableTitle)
    algoliaSearchableDescription = checkAndCombineStrings(data.meta_description, algoliaSearchableDescription)
    algoliaSearchableDescription = checkAndCombineStrings(newsArticleDescription, algoliaSearchableDescription)

    return [
      {
        objectID: `${lang}-newsArticle-${newsParentUID}-${uid}`,
        type: 'subpage',
        title: `${replaceDoubleHyphen(newsArticleTitle, '')}`,
        searchableTitle: algoliaSearchableTitle,
        description: newsArticleShortDescription,
        searchableDescription: algoliaSearchableDescription,
        image: '',
        isFeatured: true,
        slug: `/${newsParentUID}/${uid}`,
      },
    ]
  })
}

const getSliceData = (data: IPage.ISlice[] | ISubPage.ISlice[] | IFrontPage.ISlice[]): ISliceData[] => {
  return data.flatMap((slice: IPage.ISlice | ISubPage.ISlice | IFrontPage.ISlice) => {
    let title = ''
    let searchableTitle = ''
    let description = ''
    let searchableDescription = ''
    const sliceType = slice.slice_type
    switch (sliceType) {
      case 'document_slice': {
        //TODO, how to handle document slices implemented on different locations...
        break
      }
      case 'searchpage': {
        // nothing to do here...
        break
      }
      case 'iframe': {
        // nothing to do here...
        break
      }
      case 'page_cards': {
        // nothing to do here...
        break
      }
      case 'cta_card': {
        //Nothing to do here...
        break
      }
      case 'latest_news_articles': {
        //Nothing to do here...
        break
      }
      case 'featured_pages': {
        //Nothing to do here...
        break
      }
      case 'three_column_icon_cards': {
        const { primary, items } = slice as IThreeColumnIconCardsSlice
        const sliceTitle = replaceDoubleHyphen(primary.three_column_title, '')
        title += sliceTitle
        searchableTitle += sliceTitle + ' '
        items.flatMap(({ three_column_card_title, three_column_card_description }) => {
          searchableTitle += three_column_card_title + ' '
          description += three_column_card_description.flatMap(({ text }) => text).join(' ')
        })
        break
      }
      case 'image_cards': {
        const { primary } = slice as IImageCardSlice
        const sliceTitle = replaceDoubleHyphen(primary.image_card_title, '')
        title += sliceTitle
        searchableTitle += sliceTitle + ' ' + primary.image_card_label
        primary.image_card_description.flatMap(({ text }) => {
          description += text + ' '
        })
        break
      }
      case 'two_column_info_cards': {
        const { items, primary } = slice as ITwoColumnInfoCardsSlice
        title += replaceDoubleHyphen(primary.two_column_info_title, '')
        searchableTitle += title + ' '
        items.map(({ two_column_info_card_title, two_column_info_card_description }) => {
          searchableTitle += two_column_info_card_title + ' '
          description += two_column_info_card_description.flatMap(({ text }) => text).join(' ')
        })
        break
      }
      case 'two_column_cards': {
        const { items } = slice as ITwoColumnCardsSlice
        const itemsCount = items.length - 1
        items.map(({ list_card_title, list_card_description }, index) => {
          title += replaceDoubleHyphen(list_card_title, '')
          if (index != itemsCount) {
            title += ' | '
          }
          searchableTitle += list_card_title + ' '
          description += list_card_description.flatMap(({ text }) => text).join(' ')
        })
        break
      }
      case 'faq_cards': {
        let faqCardsHits: ISliceData[] = []
        const { items, primary } = slice as IFAQCards
        title += replaceDoubleHyphen(primary.title[0].text, '')

        searchableTitle += title + ' '

        items.map(({ question, answer }, index) => {
          //          searchableTitle = question[0].text + ' '
          description = answer.flatMap(({ text }) => text).join(' ') + '\n'
          faqCardsHits.push({
            title: question[0].text,
            searchableTitle: replaceDoubleHyphen(primary.title[0].text, '') + ' ' + question[0].text,
            description: description,
            searchableDescription: description,
            sliceType,
            itemIndex: index,
          })
        })
        return faqCardsHits
      }
      case 'department_info': {
        const { items, primary } = slice as IDepartmentInfoSlice
        title += replaceDoubleHyphen(primary.department_title, '')
        searchableTitle += title + ' '
        items.map(({ employee_name, job_description, email, work_phone, cell_phone }) => {
          description += employee_name + ' - ' + job_description + '\n'
          if (employee_name != null) {
            searchableDescription += employee_name + ' '
          }
          if (job_description != null) {
            searchableDescription += job_description + ' '
          }
          if (email != null) {
            searchableDescription += email + ' '
          }
          if (work_phone != null) {
            searchableDescription += work_phone + ' '
          }
          if (cell_phone != null) {
            searchableDescription += cell_phone + ' '
          }
        })
        break
      }

      case 'rich_text': {
        const { primary } = slice as IWysiwygSlice
        title += replaceDoubleHyphen(primary.title, '')
        searchableTitle += title
        description += primary.text.flatMap(({ text }) => text).join(' ')
        break
      }
      case 'text_card': {
        const { primary } = slice as ITextCardSlice
        title += replaceDoubleHyphen(primary.title, '')
        searchableTitle += title
        description += primary.description.flatMap(({ text }) => text).join(' ')
        break
      }
      case 'service_providers': {
        const { items, primary } = slice as IServiceProvidersSlice
        title += replaceDoubleHyphen(primary.title, '')
        searchableTitle += title
        items.map(({ car_type, provider, address, phone }) => {
          searchableDescription += car_type + ' '
          searchableDescription += provider.flatMap(({ text }) => text).join(' ')
          searchableDescription += ' ' + address + ' ' + phone + ' '
          description += car_type + '/'
          description += provider.flatMap(({ text }) => text).join('') + ', '
        })
        break
      }

      default: {
        //This console.log is good to remind devs to implement the slice for Search as well.
        console.log(sliceType, ' has not been implemented in the search module!')
        return []
      }
    }
    /* Most of the times, the description contains all the information, 
    but sometimes we don't want to include stuff in the description,
     but is relevant for the search. */
    if (searchableDescription === '') {
      searchableDescription = description
    }
    return { title, searchableTitle, description, searchableDescription, sliceType }
  })
}
