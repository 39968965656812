import styled, { css } from 'styled-components'
import { Translations } from '../../data/Translations'
import { INotificationBanner } from '../../prismicTypes'
import Typography from '../../styles/typography'
import { isPrismicLinkEmpty } from '../../utils/helpers'
import MainGridContainer from '../grid/MainGridContainer'
import PrismicInlineLink from '../Links/PrismicInlineLink'

interface IProps {
  banner?: INotificationBanner
}

const NotificationBanner: React.FC<IProps> = ({ banner }: IProps) => {
  if (!banner) {
    return null
  }

  const { title, description, link_to_page, visible, link_text_override } = banner?.data

  const { common } = Translations.is

  return visible ? (
    <MainGridContainer>
      <SCBanner>
        <SCTitle>{title}</SCTitle>
        <SCDescription>{description}</SCDescription>
        {!isPrismicLinkEmpty(link_to_page) && (
          <PrismicInlineLink
            extraWrapperStyle={css`
              margin-bottom: 1.5rem;
            `}
            link={link_to_page}
          >
            {link_text_override ?? common.checkItOut}
          </PrismicInlineLink>
        )}
      </SCBanner>
    </MainGridContainer>
  ) : null
}

export default NotificationBanner

const SCBanner = styled.div`
  ${({ theme: { colors } }) => css`
    grid-column: 1 / -1;
    padding: 1rem 2.5rem;
    background-color: ${colors.grey100};
    border-radius: 0.5rem;
  `}
`

const SCTitle = styled.p`
  ${Typography.h5};
`

const SCDescription = styled.p`
  ${Typography.pSmall};
`
