import { DefaultTheme, FlattenInterpolation, ThemeProps } from 'styled-components'

export interface StyleSheet {
  [key: string]: React.CSSProperties
}

export type InstallmentRowType = {
  month: number
  paymentDate: string
  installment: number
  interest: number
  paymentFee: number
  totalPayment: number
  remainder: number
}

export type ExtraStyle = FlattenInterpolation<ThemeProps<DefaultTheme>>

export enum Gutters {
  Both,
  LeftOnly,
  None,
  RightOnly,
}

export type Language = 'is' | 'en' | 'pl'
