import styled, { css } from 'styled-components'
import { PrismicLinkProps } from '../../prismicBaseTypes'
import Typography from '../../styles/typography'
import PrismicLink from './PrismicLink'
import IconBubbles from '../../public/svg/IconBubbles'
import IconCalculator from '../../public/svg/IconCalculator'
import IconUser from '../../public/svg/IconUser'
import { TIconType } from '../../prismicTypes'
import React from 'react'
import IconQuestionBubbles from '../../public/svg/IconQuestionBubbles'

interface IProps {
  icon: TIconType
  text: string
  link: PrismicLinkProps
  font?: 'normal' | 'bold'
}

const getIcon = (iconType: TIconType) => {
  switch (iconType) {
    case 'bubbles':
      return <IconBubbles />
    case 'questionBubbles':
      return <IconQuestionBubbles />
    case 'user':
      return <IconUser />
    case 'calculator':
      return <IconCalculator />
    default:
      return ''
  }
}

const PrismicIconLink: React.FC<IProps> = ({ icon, text, link, font = 'bold' }) => {
  return (
    <SCIconLink font={font}>
      {getIcon(icon)}
      <PrismicLink link={link} extraStyle={SCLink}>
        <p>{text}</p>
      </PrismicLink>
    </SCIconLink>
  )
}

export default PrismicIconLink

const SCIconLink = styled.div<{ font?: 'normal' | 'bold' }>`
  ${({ font }) => css`
    display: flex;
    flex-direction: row;
    width: 60%;
    display: flex;
    padding-top: 1rem;
    > a {
      ${font === 'bold' ? Typography.eyebrow : Typography.buttonText}
    }

    & > svg {
      align-self: center;
      margin-right: 1rem;
    }
  `}
`

const SCLink = css`
  ${({ theme: { colors } }) => css`
    border-bottom: 0.125rem solid ${colors.white};
    transition: all 320ms ease;
    align-self: center;
    & > p {
      margin: 0 0 0.0625rem;
    }
    &:hover {
      border-bottom: 0.125rem solid ${colors.blue200};
    }
  `}
`
